import { Box } from "@material-ui/core";
import { Button, Divider } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { FontFamily, FontSize, FontWeight, Spacing } from "../../v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import AddressLocationPin from "../../../images/disabled-calendar.svg";
import { dropdownStylesCombined } from "../../Dropdown";
import DayPicker, { RangeModifier } from "react-day-picker";
import "../../../styles/DayPicker.css";
import "react-day-picker/lib/style.css";
import { formatDate, getDateObject, isSelectedDateBeforeEndDate } from "../../../utils/date.util";
import { useMobile } from "../../../hooks/mobile";

const DateRangeModal = ({
  filter,
  handleFilterApplied,
  setShowDateFilter,
}: {
  filter: any;
  handleFilterApplied: any;
  setShowDateFilter: any;
}) => {
  const [range, setRange] = useState<RangeModifier>({
    from: (filter.startDate && new Date(filter.startDate)) || undefined,
    to: (filter.endDate && new Date(filter.endDate)) || undefined,
  });
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(range.from || undefined);
  const [hoveredDate, setHoveredDate] = useState<null | Date>(null);

  const handleDayHovered = (day: Date) => {
    setHoveredDate(day);
  };

  const handleDaySelected = (day: Date) => {
    setSelectedDay(day);
    const rangeObj: RangeModifier = { from: day, to: day };
    setRange(rangeObj);
    filter.startDate = formatDate({
      date: rangeObj.from,
      format: "YYYY-MM-DD",
    });
    filter.endDate = formatDate({
      date: rangeObj.to,
      format: "YYYY-MM-DD",
    });
    if (isMobile) {
      setShowDateFilter(false);
    }
  };

  const getPreferredModifier = () => {
    let fromDate = filter.startDate ? getDateObject(filter.startDate) : range.from;
    let toDate = filter.endDate ? getDateObject(filter.endDate) : undefined;

    if (!fromDate) return;

    if (hoveredDate) {
      const isBeforeSelected = isSelectedDateBeforeEndDate(hoveredDate, fromDate);
      if (isBeforeSelected) {
        toDate = hoveredDate || toDate;
      } else {
        toDate = hoveredDate;
      }
    }

    return {
      from: fromDate,
      to: toDate,
    };
  };

  const preferredModifiers = getPreferredModifier();

  const handleClear = () => {
    filter.startDate = null;
    filter.endDate = null;
    handleFilterApplied();
    setShowDateFilter(false);
  };

  const applyFilters = () => {
    handleFilterApplied();
    setShowDateFilter(false);
  };
  const isMobile = useMobile();

  return (
    <>
      <Box
        position="absolute"
        style={{
          ...dropdownStylesCombined,
          marginTop: Spacing.S4,
          width: isMobile ? "auto" : "100%",
        }}
        minWidth={isMobile ? "" : 340}
      >
       
        <Box flex={1} mt={Spacing.S2}   >
          <DayPicker
            firstDayOfWeek={1}
            {...{ disabledDays: { before: new Date() } }}
            onDayMouseLeave={() => setHoveredDate(null)}
            onDayMouseEnter={handleDayHovered}
            onDayClick={(day, modifiers) => {
              if (modifiers.disabled) return;
              handleDaySelected(day);
            }}
            modifiers={preferredModifiers}
            selectedDays={[hoveredDate || preferredModifiers?.from]}
          />
        </Box>

        {!isMobile && <Divider  />}

        {!isMobile && (
          <Box display="flex" justifyContent="space-between" padding={isMobile ? Spacing.S1 : Spacing.S6}>
            <Button
              variant="text"
              sx={{
                fontFamily: FontFamily.Museo,
                color: filter.startDate ? Colors.TextDanger : Colors.Grey,
                textTransform: "none",
                fontWeight: FontWeight.Bold,
              }}
              onClick={handleClear}
            >
              Clear all
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: Colors.TurquoiseBlue,
                fontFamily: FontFamily.Museo,
                color: Colors.White,
                textTransform: "none",
                fontWeight: FontWeight.Bold,
                "&:hover": {
                  backgroundColor: Colors.TurquoiseBlue,
                  boxShadow: "none",
                },
                boxShadow: "none",
              }}
              onClick={applyFilters}
            >
              Apply
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default function DateRangeFilter({
  filter,
  handleFilterApplied,
  stylesProps,
  setCloseOthers,
  closeOthers,
}: {
  filter: any;
  handleFilterApplied: any;
  stylesProps?: React.CSSProperties;
  setCloseOthers?: any;
  closeOthers?: boolean | string;
}) {
  const [showDateFilter, setShowDateFilter] = useState(false);
  const isMobile = useMobile();
  useEffect(() => {
    if (closeOthers !== "DateRangeFilter") {
      setShowDateFilter(false);
    }
  }, [closeOthers]);

  const modalRef = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setShowDateFilter(false); 
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowDateFilter]);

  return (
    <div ref={modalRef}>
      <Box position="relative">
        <Button
          variant="outlined"
          endIcon={
            <img
              src={AddressLocationPin}
              color={Colors.Grey}
              style={{ marginRight: 8, color: Colors.Grey, position: "absolute", right: 10, top: 12 }}
              alt={"select date"}
            />
          }
          title={
            filter.startDate
              ? formatDate({
                date: filter.startDate,
                format: "D MMM YYYY",
              })
              : "Select date"
          }
          sx={{
            height: Spacing.S11,
            width: "100%",
            minWidth:"200px",
            fontFamily: FontFamily.Museo,
            fontSize: FontSize.F16,
            fontWeight: FontWeight.Medium,
            color: filter.startDate ? Colors.TurquoiseBlue : Colors.Grey,
            borderColor: Colors.LightBlueGrey,
            padding: "0px 24px",
            borderRadius: "100px",
            textTransform: "none",
            textAlign: "left",
            justifyContent: "space-between",
            whiteSpace: "nowrap",
            "&:hover": {
              borderColor: Colors.LightBlueGrey,
              backgroundColor:Colors.CornflowerBlue,
              
            },
            "&:focus-visible": {
              borderColor: Colors.LightBlueGrey,
            },
            ...stylesProps,
          }}
          onClick={() => {
            setShowDateFilter(!showDateFilter);
            setCloseOthers("DateRangeFilter");
          }}
        >
          {filter.startDate
            ? formatDate({
              date: filter.startDate,
              format: "D MMM YYYY",
            })
            : "Select date"}
        </Button>
        {showDateFilter && (
          <DateRangeModal
            filter={filter}
            handleFilterApplied={handleFilterApplied}
            setShowDateFilter={setShowDateFilter}
          />
        )}
      </Box>
    </div>
  );
}
