import Box from "@material-ui/core/Box";
import { BOOKING_STATUS, JobStatus } from "../../../constants/booking";
import { Colors } from "../../../constants/colors";
import BookingStatusPill from "../../Bookings/BookingStatusPill";
import { useMobile } from "../../../hooks/mobile";
import { Spacing } from "../../v2/Styled/enum";

interface Props {
  jobId: number | string;
  jobStatus: JobStatus;
  bookingStatus: string;
}

const JobHeaderSubtitle = ({ jobId, jobStatus, bookingStatus }: Props) => {
  const isMobile = useMobile();
  return (
    <Box
      display={"flex"}
      alignItems={isMobile ? "flex-start" : "center"}
      gridGap={Spacing.S3}
      mt={1}
      mb={isMobile ? Spacing.S4 : Spacing.S0}
      flexDirection={isMobile ? "column" : "row"}
    >
      <Box fontSize={16} fontFamily={"Museo"} color={Colors.Dusk}>
        Booking #{jobId}
      </Box>
      {bookingStatus === BOOKING_STATUS.DECLINED ? (
        <BookingStatusPill status={jobStatus} bookingStatus={bookingStatus} />
      ) : (
        <BookingStatusPill status={jobStatus} />
      )}
    </Box>
  );
};

export default JobHeaderSubtitle;
