import { Box, CircularProgress, Link, Button as MUIButton } from "@material-ui/core";
import axios from "axios";
import braintree, { HostedFields, HostedFieldsTokenizePayload, PayPal } from "braintree-web";
import React from "react";
import { Colors } from "../../constants/colors";
import { TrackingEvents } from "../../constants/tracking";
import "../../fonts/Museo.css";
import { applePaySupported } from "../../helpers/applepay";
import { withVersion } from "../../helpers/tracking";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import PayPalButtonImage from "../../images/paypal-button.png";
import { PaymentMethod } from "../../models";
import { trackEvent } from "../../services/segment/track.service";
import { useAlertStore } from "../../stores/alert";
import { useBraintreeStore } from "../../stores/braintree";
import "../../styles/ApplePay.css";
import Button, { ButtonSize, ButtonType } from "../Button";
import Dialog from "../Dialog";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import ModalHeaderApp from "../ModalHeaderApp";
import Snackbar, { SnackbarType } from "../Snackbar";
import GoogleReCaptcha from "../GoogleReCaptcha";

declare global {
  interface Window {
    grecaptcha: any;
  }
}
interface Props {
  open: boolean;
  onClose: () => unknown;
  onAddedNewCard: (method: PaymentMethod) => unknown;
  cardOnly?: boolean;
  canAddPaypal?: boolean;
}

export default function AddNewCardModal({
  open,
  onClose,
  onAddedNewCard,
  cardOnly = false,
  canAddPaypal,
}: Props): JSX.Element {
  const { clientToken, fetchClientToken } = useBraintreeStore();
  const accessToken = useAccessToken();
  const isMobile = useMobile();

  const [hostedFieldsInstance, setHostedFieldsInstance] = React.useState<HostedFields | null>(null);
  const [payload, setPayload] = React.useState<HostedFieldsTokenizePayload | null>(null);

  const [loading, setLoading] = React.useState(true);
  const [adding, setAdding] = React.useState(false);

  const [paypalLoading, setPaypalLoading] = React.useState(false);
  const [paypalInstance, setPaypalInstance] = React.useState<PayPal | null>(null);

  const [recaptchaSuccess, setRecaptchaSuccess] = React.useState(false);

  const { setErrorMessage, errorMessage } = useAlertStore();

  React.useEffect(() => {
    if (!clientToken) {
      fetchClientToken();
    }
  }, []);

  React.useEffect(() => {
    if (clientToken && open) {
      // console.debug("clientToken: ", clientToken);
      braintree.client.create(
        {
          authorization: clientToken,
        },
        (error, clientInstance) => {
          // create paypal instance
          setPaypalLoading(true);

          braintree.paypal.create({ client: clientInstance }, (paypalErr, paypalInstance) => {
            setPaypalLoading(false);

            if (!paypalErr && paypalInstance) {
              setPaypalInstance(paypalInstance);
            }
          });

          // Create card instance
          braintree.hostedFields
            .create({
              client: clientInstance,
              styles: {
                input: {
                  "font-size": "18px",
                  "font-family": "monospace", // REMINDER: Custom fonts not allowed
                },
                ":focus": {
                  color: "black",
                },
                "::placeholder": {
                  color: "#BBBBBB",
                  "font-weight": "300",
                },
              },
              fields: {
                number: {
                  selector: "#card-number",
                  placeholder: "Enter card number",
                },
                cvv: {
                  selector: "#cvv",
                  placeholder: "234",
                },
                expirationDate: {
                  selector: "#expiration-date",
                  placeholder: "12/24",
                },
              },
            })
            .then((hostedFieldsInstance) => {
              setHostedFieldsInstance(hostedFieldsInstance);

              setLoading(false);
            })
            .catch((error) => {
              console.debug("hosted fields error: ", error);
            });
        }
      );
    }
  }, [open, clientToken]);

  const inputLabelStyle = {
    fontFamily: "Museo",
    fontSize: "14px",
    fontWeight: 700,
    color: Colors.Dusk,
  };

  const inputWrapperStyle = {
    border: "none",
    borderBottom: "1px solid rgba(0, 0, 0, .26)",
    height: "40px",
  };

  const onSubmit = () => {
    if (!recaptchaSuccess) {
      setErrorMessage("Please complete reCAPTCHA");
      return;
    }

    if (hostedFieldsInstance) {
      // console.debug("instance: ", hostedFieldsInstance);
      hostedFieldsInstance
        .tokenize()
        .then((payload) => {
          setPayload(payload);

          // console.debug("payload: ", payload);

          const { nonce, details } = payload;
          const { lastFour, expirationMonth, expirationYear, cardType } = details;

          setAdding(true);

          axios
            .post("/paymentmethods", {
              accessToken,
              nonce,
              last4: lastFour,
              expiryMonth: expirationMonth,
              expiryYear: expirationYear,
              cardType,
            })
            .then((response) => {
              setAdding(false);
              onAddedNewCard(response.data);

              trackEvent(TrackingEvents.CardAdded, withVersion());
            })
            .catch((error) => {
              setErrorMessage("Please check your card details.");

              setAdding(false);
            });
        })
        .catch((error) => {
          console.debug("tokenize error: ", error);
          setErrorMessage("Please check your card details.");

          setAdding(false);
        });
    }
  };

  return (
    <Dialog open={open} maxWidth={false} fullScreen={isMobile} onClose={onClose}>
      <Box bgcolor="white" width={isMobile ? "100%" : "640px"} borderRadius="11px">
        {isMobile ? (
          <ModalHeaderApp
            title="Add a card"
            subtitle="Your card will be added to your account for future payments."
          />
        ) : (
          <ModalHeader
            title="Add a card"
            subtitle="Your card will be added to your account for future payments."
            onClose={onClose}
          />
        )}

        <form style={loading ? { display: "none" } : {}} id="my-sample-form" className="scale-down">
          <Box padding="40px" paddingBottom={isMobile ? "30px" : "100px"}>
            <div className="paypal" style={{ paddingBottom: "10px" }}>
              {!cardOnly && canAddPaypal && paypalInstance && (
                <>
                  <MUIButton
                    onClick={() => {
                      console.debug("boom");
                      paypalInstance?.tokenize({ flow: "vault" }, (tokenizeErr, payload) => {
                        if (tokenizeErr) {
                          console.debug("error: ", tokenizeErr);
                          return;
                        }

                        console.debug("payload: ", payload);

                        if (payload) {
                          const { nonce, details } = payload;
                          const { email } = details;

                          setAdding(true);

                          axios
                            .post("/paymentmethods", {
                              accessToken,
                              nonce,
                              email,
                            })
                            .then((response) => {
                              setAdding(false);
                              onAddedNewCard(response.data);
                            })
                            .catch((error) => {
                              console.debug("add payment method error: ", error);

                              setAdding(false);
                            });
                        }
                      });
                    }}
                  >
                    <img src={PayPalButtonImage} />
                  </MUIButton>
                </>
              )}
            </div>

            {!cardOnly && applePaySupported() && (
              <Link
                className={"apple-pay-button-with-text"}
                style={{
                  width: 300,
                  height: 50,
                }}
                onClick={() => {}}
              />
            )}

            <div
              className="cardinfo-card-number"
              style={{ marginTop: "21px", marginBottom: "21px" }}
            >
              <Box style={inputLabelStyle}>Card number</Box>
              <div className="input-wrapper" id="card-number" style={inputWrapperStyle}></div>
            </div>

            <div
              className="cardinfo-wrapper"
              style={{
                marginTop: "42px",
                marginBottom: "21px",
                flexDirection: isMobile ? "column" : "row",
                display: "flex",
              }}
            >
              <div className="cardinfo-exp-date" style={{ marginRight: "12px" }}>
                <Box style={inputLabelStyle}>Expiry</Box>
                <div className="input-wrapper" id="expiration-date" style={inputWrapperStyle}></div>
              </div>

              <div
                className="cardinfo-cvv"
                style={isMobile ? { marginTop: "42px" } : { marginLeft: "12px" }}
              >
                <Box style={inputLabelStyle}>CVC</Box>
                <div className="input-wrapper" id="cvv" style={inputWrapperStyle}></div>
              </div>
            </div>

            <Box mt={4}>
              <GoogleReCaptcha
                useCustomProvider={isMobile}
                elementID={isMobile ? "google-captcha-section" : "connect-a-card"}
                verifyCallback={() => {
                  setRecaptchaSuccess(true);
                }}
              />
            </Box>
          </Box>

          <ModalFooter>
            {!isMobile && (
              <>
                <Button
                  size={ButtonSize.small}
                  width="130px"
                  type={ButtonType.outlined}
                  title="Back"
                  onClick={onClose}
                />
                <Box width="14px" />
              </>
            )}
            <Button
              size={ButtonSize.small}
              width="130px"
              title="Add card"
              loading={adding}
              onClick={onSubmit}
            />
          </ModalFooter>
        </form>

        {loading && <CircularProgress style={{ color: Colors.NightBlue, margin: "42px" }} />}
      </Box>
      <Snackbar
        type={SnackbarType.error}
        message={errorMessage}
        open={errorMessage !== null}
        onClose={() => setErrorMessage(null)}
      />
    </Dialog>
  );
}
