// Helper function to hash data using SHA-256
const hashData = async (data: any) => {
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(data.trim().toLowerCase());
  const hashBuffer = await crypto.subtle.digest("SHA-256", encodedData);
  return Array.from(new Uint8Array(hashBuffer))
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
};

export { hashData };
