import { Box } from "@material-ui/core";

import { Colors } from "../../constants/colors";
import { PaymentMethod } from "../../models";
import TextLink from "../TextLink";
import ExpiryInfo from "./ExpiryInfo";
import { capitalizeFirstLetter } from "../../helpers/string";

interface Props {
  paymentMethod: PaymentMethod;
  onDeleteClicked: () => unknown;
}

export default function AddressItem({ paymentMethod, onDeleteClicked }: Props): JSX.Element {
  const title = () => {
    if (paymentMethod.type.toLowerCase() === "paypal") {
      return paymentMethod.email;
    }

    return `${capitalizeFirstLetter(paymentMethod.cardType)} ending ${paymentMethod.last4}`;
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      paddingRight={4}
      border="1px solid #C5CBD6"
      borderRadius="8px"
      height="72px"
      alignItems="center"
      justifyContent="space-between"
      pl="16px"
      pr="16px"
      bgcolor="white"
      mb="16px"
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <img
          src={PaymentMethod.iconFor(paymentMethod)}
          width="37.5px"
          height="24px"
          alt={title()}
        />

        <Box ml={1}>
          <Box
            fontFamily="Museo"
            fontSize="16px"
            fontWeight={400}
            color={Colors.Dusk}
            display="flex"
            flexDirection="row"
            flex={1}
          >
            {title()}
          </Box>
          <ExpiryInfo
            isExpired={paymentMethod.isExpired}
            expiryYear={paymentMethod.expiryYear}
            expiryMonth={paymentMethod.expiryMonth}
          />
        </Box>
      </Box>

      <TextLink title="Remove" destructive onClick={onDeleteClicked} />
    </Box>
  );
}
