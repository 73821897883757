import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { SWRConfig } from "swr";
import App from "./App";
import queryClient from "./config/queryClient";
import { Colors } from "./constants/colors";
import { BASE_URL, SEGMENT_KEY } from "./constants/common";
import "./fonts/Museo.css";
import "./fonts/Nunito.css";
import "./fonts/OpenSans.css";
import "./fonts/SFUIText.css";
import "./fonts/Worksans.css";
import reportWebVitals from "./reportWebVitals";
import { resetUserData } from "./services/auth/auth.service";
import { store } from "./stores/V2";
import "./utils/flatMapPollyFill";
import firebaseApp from "./services/firebaseApp";
import { HelmetProvider } from "react-helmet-async";
import "./utils/flatMapPollyFill";
import { getGtmId } from "./services/gtm/dataLayer.service";
import { environment } from "./constants/common";

// init firebase app
firebaseApp.init();

// Sentry
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://48bff082741b4cc3bb184354388ba095@o128332.ingest.sentry.io/5852180",
    integrations: [new Integrations.BrowserTracing()],
    allowUrls: [/getblys.com.au/, /googleapis.com/, /google.com\/recaptcha/, /braintree-api.com/],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      "Request failed with status code 401",
      "Network Error",
      "timeout of 0ms exceeded",
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      "NetworkError",
      "Failed to fetch",
      "Load failed",
      "TypeError: Failed to fetch",
      "AbortError",
    ],
  });
}

// Global vars
declare global {
  interface Window {
    analytics: any;
    AfterPay: any;
    ApplePaySession: any;
  }
}

// Segment
if (process.env.NODE_ENV === "production") {
  window.analytics.load(SEGMENT_KEY);
}

const theme = createMuiTheme({
  palette: {
    error: {
      main: "#D5563D",
    },
    success: {
      main: "#74AC56",
    },
  },
  typography: {
    fontFamily: "Museo",
    fontWeightRegular: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      fontSize: "42px",
      color: Colors.NightBlue,
    },
    h2: {
      fontWeight: 700,
      fontSize: "36px",
      color: Colors.NightBlue,
    },
    h3: {
      fontWeight: 500,
      fontSize: "20px",
      color: Colors.Dusk,
    },
    h4: {
      fontWeight: 700,
      fontSize: "14px",
    },
  },
});

axios.defaults.baseURL = BASE_URL;

axios.interceptors.response.use(undefined, (error) => {
  const { response } = error;
  if (
    response &&
    response.status === 401 &&
    localStorage.getItem("token") &&
    window.location.pathname !== "/bookings"
  ) {
    resetUserData();
    window.location.replace("/login");
  }
  return Promise.reject(error);
});

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

const initGoogleTagManager = async () => {
  const GTM = await getGtmId();
  const tagManagerArgs = {
    gtmId: GTM,
  };
  TagManager.initialize(tagManagerArgs);
};

if (environment.isProduction) {
  initGoogleTagManager();
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SWRConfig
        value={{
          fetcher,
          shouldRetryOnError: false,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </HelmetProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SWRConfig>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
