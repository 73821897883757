import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Box } from "@mui/material";
import { StripeElementChangeEvent, StripeError, Token } from "@stripe/stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import "../../../fonts/Museo.css";
import * as Styled from "../../v2/Styled/enum";
import { getValue } from "../../../utils/object";
import { useUserStore } from "../../../stores/user";
import { useAlertStore } from "../../../stores/alert";
import { useStripeStore } from "../../../stores/stripe";
import { StripeClientErrorType } from "../../../constants/stripe";
import { stripeService } from "../../../services/stripe/customer";
import { LOG_CHANNEL, sendErrorLog } from "../../../services/log/log.service";
import StripeCardField, { CARD_DETAILS_FIELD, StripeCardFieldType } from "./StripeCardField";

interface Props {
  onTokenCreated: (
    e: MouseEvent,
    token: Token | undefined,
    cb: (e: MouseEvent, d: any) => unknown
  ) => void;
  forPayment?: boolean;
  bypassCardAddUI?: boolean;
  FooterComponent?: React.ReactNode;
}

export interface StripeCardFormHandle {
  onSubmit: (e: MouseEvent, cb: (e: MouseEvent, d: any) => unknown) => Promise<void>;
}

const StripeCardElement = React.forwardRef(
  (
    { onTokenCreated, forPayment = false, FooterComponent, bypassCardAddUI = false }: Props,
    ref: React.Ref<StripeCardFormHandle>
  ) => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useUserStore();
    const { setErrorMessage } = useAlertStore();
    const { clientSecret, createSetupIntent, setStripe, setElements } = useStripeStore();

    useEffect(() => {
      if (!bypassCardAddUI || !stripe || !elements) return;
      setStripe(stripe);
      setElements(elements);
    }, [stripe, elements, bypassCardAddUI]);

    const cardNumberRef = useRef<any>(null);
    const cardExpiryRef = useRef<any>(null);
    const cardCvcRef = useRef<any>(null);

    const [cardError, setCardError] = useState({
      number: "",
      expiry: "",
      cvc: "",
      country: "",
    });

    const onError = (message = "") => setErrorMessage(message);

    // Customizes the ref object that is exposed to the parent component
    useImperativeHandle(ref, () => ({
      onSubmit: (e: MouseEvent, cb: (e: MouseEvent, d: any) => unknown) =>
        stripeService.createNewCard(e, cb, {
          user,
          stripe,
          onError,
          elements,
          forPayment,
          clientSecret,
          onTokenCreated,
          createSetupIntent,
        }),
    }));

    const handleCardInfoChange = (field: StripeCardFieldType, event: StripeElementChangeEvent) => {
      const errorMessage = getValue(event, "error.message");

      const nextFieldRef = {
        [CARD_DETAILS_FIELD.NUMBER]: cardExpiryRef,
        [CARD_DETAILS_FIELD.EXPIRY]: cardCvcRef,
        [CARD_DETAILS_FIELD.CVC]: null,
      }[field];

      if (event.complete && nextFieldRef?.current) {
        nextFieldRef.current.focus();
      }

      setCardError({
        ...cardError,
        [field]: errorMessage ? errorMessage : "",
      });
    };

    return (
      <Box
        display={Styled.Display.Flex}
        gap={Styled.Spacing.S6}
        flexDirection={Styled.FlexDirection.Column}
      >
        <StripeCardField
          ref={cardNumberRef}
          title={"Card number"}
          errorMessage={cardError.number}
          onChange={handleCardInfoChange}
          field={CARD_DETAILS_FIELD.NUMBER}
        />

        <Box display={Styled.Display.Flex} gap={Styled.Spacing.S4}>
          <StripeCardField
            title={"Expiry"}
            ref={cardExpiryRef}
            errorMessage={cardError.expiry}
            onChange={handleCardInfoChange}
            field={CARD_DETAILS_FIELD.EXPIRY}
          />

          <StripeCardField
            ref={cardCvcRef}
            title={"Security code"}
            errorMessage={cardError.cvc}
            field={CARD_DETAILS_FIELD.CVC}
            onChange={handleCardInfoChange}
          />
        </Box>

        {FooterComponent}
      </Box>
    );
  }
);

export default StripeCardElement;
