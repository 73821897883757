import { useQuery } from "@tanstack/react-query";
import { get } from "../../api/client";

export const BILLING_COMPANIES_KEY = {
  billingCompaniesOptions: "billingCompaniesOptions",
};

export const useGetBillingCompanies = () => {
  return useQuery([BILLING_COMPANIES_KEY.billingCompaniesOptions], () =>
    get("api/v2/billingCompanies").then((res) => res.data)
  );
};
