import { Box, InputBase, withStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Colors } from "../../constants/colors";
import {
  INVOICE_DELIVERY_FORMAT,
  INVOICE_FORMAT,
  INVOICE_FORMAT_ENUM,
  INVOICE_ISSUE,
  PAYMENT_RECEIPT_PREFERENCE_OPTIONS,
  PAYMENT_TERMS,
  SHOULD_ITEMIZE_SURCHARGES,
} from "../../constants/payment";
import { INPUT_TYPE } from "../../data/enums";
import isValidEmail from "../../helpers/validation";
import { useAlertStore } from "../../stores/alert";
import { findItem } from "../../utils/object";
import Dropdown from "../Dropdown";
import TextLink from "../TextLink";
import { isBulkInvoiceFormat } from "../../services/additionalBillingIdentifier/additionalBillingIdentifier.service";
import { MultiSelectDropDown } from "../v2/MultiSelectDropdown/MultiSelectDropdown";
import BillingCompanyName from "./BillingCompany";

interface IndividualDetailInterface {
  title: string;
  value: string | string[];
  slug: string;
  onSave: (slug: string, value: any) => unknown;
  allowEdit?: boolean;
  inputType?: string;
  additionalAction?: (value: any) => unknown;
  isB2BUser?: boolean;
}

const StyledInputBase = withStyles({
  input: {
    "&::placeholder": {
      color: Colors.LightBlueGrey,
      opacity: 1.0,
    },
  },
})(InputBase);

const IndividualDetail = ({
  title,
  slug,
  value,
  onSave,
  allowEdit = true,
  inputType = INPUT_TYPE.TEXT,
  additionalAction = (value: any) => {},
  isB2BUser = false,
}: IndividualDetailInterface) => {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(value);

  const { setErrorMessage } = useAlertStore();

  useEffect(() => {
    setContent(value);
  }, [value]);

  const handleSaveConfig = () => {
    const isValid = validate(slug, content as string);
    if (!isValid) return;
    onSave(slug, content);
    setIsEditing(false);
  };

  const handleCancelClicked = () => {
    setContent(value);
    setIsEditing(false);
  };

  const validate = (slug: string, content: string) => {
    if (slug === "primaryBillingEmail" && content && !isValidEmail(content)) {
      setErrorMessage("Please enter valid email");
      return false;
    } else if (slug === "primaryBillingEmail" && !content) {
      setErrorMessage("Primary billing address is required!");
      return false;
    } else if (slug === "secondaryBillingEmail" && content && !isValidEmail(content)) {
      setErrorMessage("Please enter valid email");
      return false;
    }
    return true;
  };

  let invoiceFormatOptions = INVOICE_FORMAT;
  if (!isB2BUser) {
    invoiceFormatOptions = (invoiceFormatOptions || []).filter(
      ({ value }) =>
        !(
          value === INVOICE_FORMAT_ENUM.BULK_INVOICE_FOR_EACH_PROVIDER ||
          value === INVOICE_FORMAT_ENUM.BULK_INVOICE_FOR_ALL_RECIPIENTS ||
          value === INVOICE_FORMAT_ENUM.BULK_INVOICE_FOR_EACH_RECIPIENT ||
          value === INVOICE_FORMAT_ENUM.BULK_INVOICE_FOR_ALL_RECIPIENTS_EXCEL ||
          value === INVOICE_FORMAT_ENUM.BULK_INVOICE_FOR_EACH_RECIPIENT_NDIS
        )
    );
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      border="1px solid #C5CBD6"
      borderRadius="8px"
      width="100%"
      bgcolor="white"
      mb="16px"
    >
      <Box
        width="100%"
        paddingTop="12px"
        paddingBottom="12px"
        paddingLeft="16px"
        paddingRight="12px"
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box width={"100%"}>
            {title && (
              <Box fontFamily="Museo" fontSize="14px" color={Colors.Dusk} fontWeight={700}>
                {title}
              </Box>
            )}
            {inputType === INPUT_TYPE.SELECT ? (
              <Box flex={1}>
                <>
                  {slug === "paymentTerm" && (
                    <>
                      {isEditing && allowEdit ? (
                        <Box mr={2}>
                          <Dropdown
                            options={PAYMENT_TERMS}
                            selectedOption={findItem(PAYMENT_TERMS, {
                              key: "value",
                              value: parseInt(content as string) || PAYMENT_TERMS[0].value,
                            })}
                            onSelectedOption={({ value }) => setContent(value)}
                            isDropdownDirectionUp={true}
                            paddingTop="0px"
                            paddingBottom="0px"
                          />
                        </Box>
                      ) : (
                        <Box style={styles.text} mt={1}>
                          {findItem(PAYMENT_TERMS, {
                            key: "value",
                            value: parseInt(content as string) || PAYMENT_TERMS[0].value,
                          })?.title || ""}
                        </Box>
                      )}
                    </>
                  )}

                  {slug === "billingCompanyName" && (
                    <BillingCompanyName
                      isEditing={isEditing}
                      allowEdit={allowEdit}
                      content={content}
                      setContent={setContent}
                    />
                  )}

                  {slug === "invoiceFormat" && (
                    <>
                      {isEditing && allowEdit ? (
                        <Box mr={2}>
                          <Dropdown
                            options={invoiceFormatOptions}
                            selectedOption={findItem(INVOICE_FORMAT, {
                              key: "value",
                              value: content || invoiceFormatOptions[0].value,
                            })}
                            onSelectedOption={({ value }) => {
                              if (isBulkInvoiceFormat(value)) {
                                additionalAction(true);
                              } else {
                                additionalAction(false);
                              }
                              setContent(value);
                            }}
                            isDropdownDirectionUp={true}
                            paddingTop="0px"
                            paddingBottom="0px"
                            styles={{
                              titleStyle: {
                                height: "auto",
                                lineHeight: "22px",
                              },
                            }}
                          />
                        </Box>
                      ) : (
                        <Box style={styles.text} mt={1}>
                          {findItem(invoiceFormatOptions, {
                            key: "value",
                            value: content || invoiceFormatOptions[0].value,
                          })?.title || ""}
                        </Box>
                      )}
                    </>
                  )}

                  {slug === "bulkInvoiceDeliveryFreq" && (
                    <>
                      {isEditing && allowEdit ? (
                        <Box mr={2}>
                          <MultiSelectDropDown
                            onSelect={({ value: selectedValue }) => {
                              let updatedValue = [...content, selectedValue];
                              if (content.includes(selectedValue)) {
                                updatedValue = (content as string[]).filter(
                                  (item: string) => item !== selectedValue
                                );
                              }
                              if (!updatedValue.length) {
                                updatedValue = [INVOICE_DELIVERY_FORMAT[0].value];
                              }
                              setContent(updatedValue);
                            }}
                            selectedValues={INVOICE_DELIVERY_FORMAT.map((format) =>
                              (content || []).includes(format.value) ? format.value : ""
                            ).filter((format) => format !== "")}
                            options={INVOICE_DELIVERY_FORMAT}
                          />
                        </Box>
                      ) : (
                        <Box style={styles.text} mt={1}>
                          {/* {findItem(INVOICE_DELIVERY_FORMAT, {
                            key: "value",
                            value: content || INVOICE_DELIVERY_FORMAT[0].value,
                          })?.title || ""} */}
                          {INVOICE_DELIVERY_FORMAT.map((format) =>
                            (value || []).includes(format.value) ? format.value : ""
                          )
                            .filter((format) => format !== "")
                            .join(" | ")}
                        </Box>
                      )}
                    </>
                  )}
                  {slug === "shouldSendReceipts" && (
                    <>
                      {isEditing && allowEdit ? (
                        <Box mr={2}>
                          <Dropdown
                            options={PAYMENT_RECEIPT_PREFERENCE_OPTIONS}
                            selectedOption={findItem(PAYMENT_RECEIPT_PREFERENCE_OPTIONS, {
                              key: "value",
                              value: content || PAYMENT_RECEIPT_PREFERENCE_OPTIONS[1].value,
                            })}
                            onSelectedOption={({ value }) => setContent(value)}
                            isDropdownDirectionUp={true}
                            paddingTop="0px"
                            paddingBottom="0px"
                          />
                        </Box>
                      ) : (
                        <Box style={styles.text} mt={1}>
                          {findItem(PAYMENT_RECEIPT_PREFERENCE_OPTIONS, {
                            key: "value",
                            value: content || PAYMENT_RECEIPT_PREFERENCE_OPTIONS[1].value,
                          })?.title || ""}
                        </Box>
                      )}
                    </>
                  )}

                  {slug === "invoiceIssue" && (
                    <>
                      {isEditing && allowEdit ? (
                        <Box mr={2}>
                          <Dropdown
                            options={INVOICE_ISSUE}
                            selectedOption={findItem(INVOICE_ISSUE, {
                              key: "value",
                              value: content,
                            })}
                            onSelectedOption={({ value }) => setContent(value)}
                            isDropdownDirectionUp={true}
                            paddingTop="0px"
                            paddingBottom="0px"
                          />
                        </Box>
                      ) : (
                        <Box style={styles.text} mt={1}>
                          {findItem(INVOICE_ISSUE, {
                            key: "value",
                            value: content || INVOICE_ISSUE[0].value,
                          })?.title || ""}
                        </Box>
                      )}
                    </>
                  )}

                  {slug === "shouldItemizeSurcharge" && (
                    <>
                      {isEditing && allowEdit ? (
                        <Box mr={2}>
                          <Dropdown
                            options={SHOULD_ITEMIZE_SURCHARGES}
                            selectedOption={findItem(SHOULD_ITEMIZE_SURCHARGES, {
                              key: "value",
                              value: content || SHOULD_ITEMIZE_SURCHARGES[1].value,
                            })}
                            onSelectedOption={({ value }) => setContent(value)}
                            isDropdownDirectionUp={true}
                            paddingTop="0px"
                            paddingBottom="0px"
                          />
                        </Box>
                      ) : (
                        <Box style={styles.text} mt={1}>
                          {findItem(SHOULD_ITEMIZE_SURCHARGES, {
                            key: "value",
                            value: content || SHOULD_ITEMIZE_SURCHARGES[1].value,
                          })?.title || ""}
                        </Box>
                      )}
                    </>
                  )}
                </>
              </Box>
            ) : (
              <Box>
                <StyledInputBase
                  disabled={!isEditing || !allowEdit}
                  onChange={(e) => setContent(e.target.value)}
                  onClick={() => {}}
                  fullWidth
                  value={content}
                  style={styles.text}
                />
                {isEditing && <Box bgcolor={Colors.LightPeriwinkle} height="1px" width="100%" />}
              </Box>
            )}
          </Box>

          <Box>
            {isEditing && allowEdit && (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gridRowGap={10}
              >
                <TextLink
                  flex={0.1}
                  title="Save"
                  onClick={() => handleSaveConfig()}
                  fontSize="16px"
                />
                <TextLink
                  flex={0.1}
                  title="Cancel"
                  onClick={() => handleCancelClicked()}
                  destructive
                  fontSize="16px"
                  marginLeft={2}
                />
              </Box>
            )}
            {!isEditing && allowEdit && (
              <TextLink
                flex={0.2}
                title="Edit"
                onClick={() => {
                  setIsEditing(true);
                }}
                fontSize="16px"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  text: {
    color: Colors.Dusk,
    fontSize: "16px",
    fontFamily: "museo",
    opacity: 1,
    WebkitTextFillColor: Colors.Dusk,
  },
};

export default IndividualDetail;
