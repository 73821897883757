import DayPicker from "react-day-picker";
import { Box, Typography } from "@material-ui/core";

import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { DayPickerNavbar } from "../../NewBooking/DateAndTime";
import { formatDateWithTimezone } from "../../../utils/date.util";
import { BookingSlot, CorporateBookingState } from "../../../stores/V2/corporate/interface";
import BookingDetailRow from "../../../components/BookingSummary/BookingDetailRow/BookingDetailRow";
import MailIcon from "../../../images/review-mail-icon.png";
import PhoneIcon from "../../../images/review-phone-icon.png";
import HomeIcon from "../../../images/review-home-icon.png";
import NoteIcon from "../../../images/review-note-icon.svg";
import SuccessIcon from "../../../images/check-success.png";
import AddressIcon from "../../../images/review-map-icon.png";
import DurationIcon from "../../../images/review-duration.png";
import DateIcon from "../../../images/review-calendar-time.svg";
import UserIcon from "../../../images/review-for-icon.png";
import CalendarIcon from "../../../images/review-calendar-add.png";
import { EVENT_TYPE } from "../../../data/corporate.options";

type Props = {
  booking: CorporateBookingState;
  step?: number;
  showCalendar?: boolean;
  showBookingInfo?: boolean;
  hideStep?: boolean;
  title?: string;
  subTitle?: string;
  slot?: BookingSlot;
};

const subtitleText =
  "Select a time below to confirm when you would like to receive your corporate massage. Please make sure to arrive 2 minutes before your turn.";

export default function CorporateInformation({
  booking,
  step = 1,
  title,
  subTitle,
  slot,
  hideStep = false,
  showCalendar = false,
  showBookingInfo = true,
}: Props) {
  const isMobile = useMobile();
  const { corporateInfo, earliestTime, user } = booking;
  let slotTime = earliestTime;
  if (slot) {
    slotTime = slot.start;
  }
  const getCorporateEventType = (eventType: string) =>
    EVENT_TYPE.find((item: any) => item.value === eventType)?.title;

  return (
    <Box marginBottom={showCalendar ? "62px" : "56px"}>
      {hideStep ? (
        <Box
          marginTop={isMobile ? "40px" : "32px"}
          width="40px"
          height="40px"
          justifyContent="center"
          alignItems="center"
          display="flex"
          marginBottom="16px"
        >
          <img src={SuccessIcon} alt="icon" style={{ maxHeight: "100%", maxWidth: "100%" }} />
        </Box>
      ) : (
        <Box
          color={Colors.Grey}
          marginTop={isMobile ? "40px" : "32px"}
          marginBottom="8px"
          fontSize="14px"
          lineHeight="21px"
          fontFamily="Open Sans"
        >
          Step {step} of 3
        </Box>
      )}
      <Typography
        variant="h1"
        style={{
          color: Colors.NightBlue,
          fontSize: "36px",
          lineHeight: "39px",
        }}
      >
        {title || booking.title}
      </Typography>
      <Typography
        style={{
          color: Colors.Dusk,
          fontSize: "16px",
          lineHeight: "24px",
          marginTop: "8px",
        }}
      >
        {subTitle || subtitleText}
      </Typography>
      <Box
        width="100%"
        mt={showCalendar ? "48px" : "32px"}
        display={isMobile ? "flex" : undefined}
        flexDirection={isMobile ? "column" : undefined}
        alignItems={isMobile ? "center" : undefined}
      >
        {showCalendar && booking.earliestTime && (
          <DayPicker
            initialMonth={new Date(booking.earliestTime)}
            firstDayOfWeek={1}
            onDayClick={(day, modifiers) => {
              if (modifiers.disabled) return;
            }}
            selectedDays={true ? [new Date(booking.earliestTime)] : []}
            disabledDays={{
              before: new Date(booking.earliestTime),
              after: new Date(booking.earliestTime),
            }}
            captionElement={() => {
              return <Box />;
            }}
            navbarElement={(props) => {
              return <DayPickerNavbar {...props} />;
            }}
          />
        )}

        {showBookingInfo && slotTime && (
          <>
            <BookingDetailRow
              iconStyle={{ scale: "1.5" }}
              icon={DurationIcon}
              text={`${corporateInfo.durationPerPerson} mins`}
            />
            <BookingDetailRow
              icon={DateIcon}
              text={formatDateWithTimezone(slotTime || "", booking.timezone, "D MMM [at] h:mma")}
            />
            <BookingDetailRow
              icon={AddressIcon}
              text={booking.address?.fullAddress || booking.address?.address || ""}
            />
            {user?.email !== "tammy.rudd@genea.com.au" && (
              <BookingDetailRow
                icon={HomeIcon}
                text={getCorporateEventType(corporateInfo.eventType) || corporateInfo.eventType}
              />
            )}
            {corporateInfo.eventName && (
              <BookingDetailRow icon={CalendarIcon} text={corporateInfo.eventName} />
            )}
            <BookingDetailRow
              icon={UserIcon}
              text={`${user?.firstName} ${user?.lastName}`}
              prefix="By"
              prefixColor={Colors.Grey}
            />
            {user?.email !== "tammy.rudd@genea.com.au" && (
              <>
                <BookingDetailRow icon={MailIcon} text={user?.email || ""} />
                <BookingDetailRow icon={PhoneIcon} text={user?.mobile || ""} />
              </>
            )}
            {corporateInfo.noteForRecipients && (
              <BookingDetailRow icon={NoteIcon} text={corporateInfo.noteForRecipients} />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
