import { Box, Typography } from "@material-ui/core";
import NoBookingsImage from "../../../images/no-bookings-placeholder.svg";
import { Cursor, FontFamily, FontSize, FontWeight, LineHeight } from "../../../components/v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import React from "react";

interface NoItemsProps {
  placeholder: string;
  subtitle: string;
  actionText: string;
  onActionHandle: () => unknown;
}

export const NoItemsPlaceholder = ({
  placeholder,
  subtitle,
  actionText,
  onActionHandle,
}: NoItemsProps) => {
  return (
    <Box
      width="100%"
      height="65vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={"column"}
    >
      <Box>
        <img src={NoBookingsImage} alt={"no bookings"} />
      </Box>
      <Box>
        <Typography style={{fontFamily: FontFamily.Museo, fontWeight: FontWeight.Bold, fontSize:FontSize.F16,lineHeight:LineHeight.L24,color:Colors.Dusk}}>
          {placeholder}
        </Typography>
      </Box>
      <Box>
        <Typography  style={{fontFamily: FontFamily.OpenSans, fontWeight: FontWeight.Regular, fontSize:FontSize.F14,lineHeight:LineHeight.L21,color:Colors.Dusk}}align={"center"}>
          {subtitle}{" "}
          <Typography
            variant="body2"
            style={{
              fontFamily: FontFamily.OpenSans, 
              fontWeight: FontWeight.Regular, 
              fontSize:FontSize.F14,
              lineHeight:LineHeight.L21,
              color:Colors.TurquoiseBlue,
              cursor:Cursor.pointer,
            }}
            onClick={() => {
              onActionHandle();
            }}
          >
            {actionText}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
