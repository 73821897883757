import React from "react";
import axios from "axios";
import { Box, ButtonBase } from "@material-ui/core";
import { Booking } from "../../models";
import { useBookingStore, updateBooking } from "../../stores/booking";
import { useHistory } from "react-router-dom";
import { useAccessToken } from "../../hooks/common";
import { useAlertStore } from "../../stores/alert";
import Divider from "../Divider";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../Button";
import InfoIcon from "../../images/info_grey.svg";
import GenderFallbackInfoModal from "../GenderFallbackInfoModal/GenderFallbackInfoModal";
import { get, isNil } from "lodash";
import { BOOKING_STATUS, GenderPreference } from "../../constants/booking";
import { getValue } from "../../utils/object";

interface Props {
  booking: Booking;
  buttonDirection?: "column" | "row";
  showHeader?: boolean;
  bookingMutate: () => unknown;
}

export default function PreferredGenderNotFoundActions({
  booking,
  buttonDirection = "column",
  showHeader = false,
  bookingMutate,
}: Props): JSX.Element {
  const history = useHistory();
  const genderPreference1 = booking.bookingdetails[0]?.genderPreference;
  const [saving, setSaving] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const accessToken = useAccessToken();
  const { setErrorMessage } = useAlertStore();

  const [genderFallbackInfoModalOpen, setGenderFallbackInfoModalOpen] = React.useState(false);
  const handleChangeGender = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setSaving(true);
    resetGenderFallbackResponse(true)
      .then((response) => {
        setSaving(false);
        history.push("/my-bookings");
      })
      .catch((error) => {
        setSaving(false);
        setErrorMessage("Unable to update booking");
      });
  };

  const handleNoChangeInGender = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setCancel(true);
    resetGenderFallbackResponse(false)
      .then((response) => {
        setCancel(false);
        history.push("/my-bookings");
      })
      .catch((error) => {
        setCancel(false);
        setErrorMessage("Unable to update booking");
      });
  };

  const buttonSpaceStyle = () => {
    if (buttonDirection === "column") {
      return {
        marginBottom: 8,
      };
    }
    return {
      marginRight: 8,
    };
  };

  const resetGenderFallbackResponse = (allowFallback: boolean) => {
    return axios
      .put(`/api/v2/bookings/${booking.id}/gender-fallback-response`, {
        accessToken,
        allowFallback,
      })
      .then((response) => {
        bookingMutate();
        history.push("/my-bookings");
      })
      .catch((error) => {
        setErrorMessage("Unable to update booking");
      });
  };

  const shouldShowGenderFallback = () => {
    const genderFallbackTimeReached =
      get(booking, "bookingdetails[0].genderFallbackTimeReached") || false;
    const bookingStatus = get(booking, "status");
    const currentPreference = get(booking, "bookingdetails[0].genderPreference");
    const allowedPreferences = [GenderPreference.femaleOnly, GenderPreference.maleOnly];

    const showGenderFallback =
      genderFallbackTimeReached &&
      bookingStatus === BOOKING_STATUS.NEW &&
      allowedPreferences.includes(currentPreference);

    return showGenderFallback;
  };

  const showGenderFallback = shouldShowGenderFallback();
  if (!showGenderFallback) return <></>;

  const profession = getValue(booking, "service.profession") || "provider";
  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Divider mt="16px" />
      {showHeader && (
        <>
          <Box
            fontFamily="Museo"
            fontWeight={700}
            color={Colors.Dusk}
            fontSize="18px"
            textAlign="left"
            mt="16px"
          >
            Sorry, we couldn’t find a{" "}
            {genderPreference1 === GenderPreference.femaleOnly ? "female" : "male"} {profession}.
          </Box>
          <Box fontFamily="Open Sans" fontSize="14px" color={Colors.Dusk} textAlign="left" mt="8px">
            Would you like to change your booking request to accept practitioners of any gender?
          </Box>
          <Box
            fontFamily="Open Sans"
            fontWeight={400}
            fontSize="14px"
            lineHeight="21px"
            color={Colors.BlueyGrey}
            textAlign="left"
            mt="8px"
          >
            Get confirmed sooner
            <ButtonBase
              onClick={(event) => {
                event.stopPropagation();
                setGenderFallbackInfoModalOpen(true);
              }}
            >
              <img style={{ marginLeft: "5px" }} src={InfoIcon} width="20px" height="20px" />
            </ButtonBase>
          </Box>
        </>
      )}

      <Box display="flex" flexDirection={buttonDirection} flex={1} mt="16px">
        <Button
          loading={saving}
          title="Yes, change to any gender"
          type={ButtonType.outlined}
          size={ButtonSize.large}
          onClick={handleChangeGender}
          style={buttonSpaceStyle()}
        />
        <Button
          loading={cancel}
          title="No, keep my booking as is"
          type={ButtonType.outlined}
          size={ButtonSize.large}
          onClick={handleNoChangeInGender}
        />
      </Box>
      <GenderFallbackInfoModal
        isOpen={genderFallbackInfoModalOpen}
        title={"Therapist Gender"}
        description={
          "To increase the speed of your booking confirmation, we suggest selecting ‘no preference’ or indicating you’re open the other genders if your preferred therapist gender is unavailable."
        }
        onClose={() => setGenderFallbackInfoModalOpen(false)}
      />
    </Box>
  );
}
