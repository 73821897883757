import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { HOME_PAGE } from "../../constants/links";
import { useMobile } from "../../hooks/mobile";
import { useUserStore } from "../../stores/user";
import Button, { ButtonType } from "../Button";
import Dialog from "../Dialog";
import { Display, FlexDirection, Spacing } from "../v2/Styled/enum";

type Props = {
  open: boolean;
  onClose: () => void;
  hasCashKeyword?: boolean;
  hasPrivateBookingPhrases: boolean;
  btnTitle?: string;
};

const getBodyAndTitle = (
  hasCashKeyword: boolean | undefined,
  hasPrivateBookingPhrases: boolean | undefined
) => {
  let title = ": you cannot share external links or private contact info";

  let body =
    "all correspondence must remain within the Blys Platform. These terms are in place to ensure the safety and privacy of all users.";

  let footerContents = [
    "Please use the in-app messaging and calling function to contact each other.",
    "Failure to adhere to this policy can result in account suspension.",
  ];

  if (hasCashKeyword) {
    title = "";
    body =
      "all payments must be made online through the Blys platform. Cash or other direct payments are not allowed.";
    footerContents = ["Failure to adhere to this policy can result in account suspension."];
  } else if (hasPrivateBookingPhrases) {
    title = "";
    footerContents = [
      "All bookings must go through Blys. ",
      "Private bookings are strictly prohibited and will result in the immediate and permanent termination of both your and your therapist's account.",
    ];
  }

  return { title, body, footerContents };
};

const PersonalInfoDisclaimerModal = ({
  open,
  onClose,
  hasCashKeyword,
  hasPrivateBookingPhrases,
  btnTitle = "Edit message",
}: Props) => {
  const isMobile = useMobile();
  const { user } = useUserStore();

  const userCountry = user?.country || "";
  const websiteHomePage = HOME_PAGE[userCountry.toUpperCase()] || HOME_PAGE.DEFAULT;

  const { title, body, footerContents } = getBodyAndTitle(hasCashKeyword, hasPrivateBookingPhrases);

  return (
    <Dialog open={open}>
      <Box
        width={isMobile ? "auto" : "330px"}
        padding={`${Spacing.S8} ${Spacing.S4} ${Spacing.S4}`}
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        style={{ rowGap: Spacing.S6 }}
      >
        <Typography
          variant="h3"
          style={{
            fontWeight: 700,
            color: Colors.Indigo,
            fontSize: "22px",
            textAlign: "center",
            lineHeight: "24.2px",
            padding: `0 ${Spacing.S2}`,
          }}
        >
          Action not allowed {title}
        </Typography>

        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          style={{ rowGap: Spacing.S4 }}
          color={Colors.BlueyGrey}
          fontWeight={500}
          textAlign="center"
          lineHeight="24px"
        >
          <Typography>
            As per our{" "}
            <a
              href={`${websiteHomePage}/terms/software-licence-agreement/`}
              target="_blank"
              rel="noreferrer"
              style={{ color: Colors.Indigo }}
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              href={`${websiteHomePage}/privacy/`}
              target="_blank"
              rel="noreferrer"
              style={{ color: Colors.Indigo }}
            >
              Privacy Policy
            </a>
            , {body}
          </Typography>

          {footerContents.map((content) => (
            <Typography> {content}</Typography>
          ))}
        </Box>

        <Button title={btnTitle} type={ButtonType.outlined} onClick={onClose} />
      </Box>
    </Dialog>
  );
};

export default PersonalInfoDisclaimerModal;
