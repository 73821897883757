import { Box } from "@material-ui/core";

import SectionHeader from "../../../components/Headers/SectionHeader/SectionHeader";
import JumboTron from "../../../components/Jumbotron/Jumbotron";
import PayoutSummaryItem from "./PayoutSummaryItem";
import { Accordion } from "../../../components/Accordion/Accordion";
import { PayoutTimeline } from "../../../components/ProDashboard/PastBookings/PayoutTimeline";

import { Colors } from "../../../constants/colors";
import { Spacing } from "../../../components/v2/Styled/enum";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { DEFAULT_CURRENCY, TOTAL_PAYOUT_INFO } from "../../../constants/booking";
import { ProPayoutBreakdown, ProPayoutBreakdownDetail } from "../../../models";

interface Props {
  job: any;
  proPayouts: any;
  onInfoClicked: (info: any) => void;
}

const PayoutSummary = ({ job, onInfoClicked, proPayouts }: Props) => {
  const booking = getValue(job, "bookingdetail.booking") || {};
  const { lateNightSurcharge, lateNightSurchargeApplied, isServiceFeeFixed } = booking;
  const isServiceFeeNotFixed =
    !isServiceFeeFixed ?? (lateNightSurcharge > 0 && !lateNightSurchargeApplied);

  const getJobServiceFee = ({ booking }: any) => {
    if (job?.serviceFeeOverride) return job.serviceFeeOverride;
    else if (booking?.serviceFeeOverride) return booking.serviceFeeOverride;

    let serviceFee = booking?.serviceFee || 0;
    return serviceFee;
  };

  const getDisplayServiceFee = ({ job }: any) => {
    const serviceFeeText = getValue(job, "approxServiceFee.text");
    if (serviceFeeText) return serviceFeeText;

    const booking = getValue(job, "bookingdetail.booking") || {};
    const serviceFee = getJobServiceFee({ booking });
    const currency = (getValue(booking, "service.selectedCountry") || DEFAULT_CURRENCY)
      ?.currencySymbol;

    const prefix = isServiceFeeNotFixed ? "Up to " : "";
    return `${prefix}${serviceFee.toPriceString(currency)}`;
  };

  const getPayoutBreakdown = ({ booking }: any) => {
    if (checkIfEmpty(booking)) return [];

    const summary = booking.payoutSummary || [];
    return summary.filter(({ value }: { value: number }) => !!value);
  };

  const showBreakdownDetail = (details?: ProPayoutBreakdownDetail) => {
    if (checkIfEmpty(details) || !details) return;

    const { description, title } = details;
    onInfoClicked({ title, description });
  };

  const showTotalPayoutDetail = () => {
    if (!isServiceFeeNotFixed) return;

    onInfoClicked(TOTAL_PAYOUT_INFO);
  };

  const serviceFee = getDisplayServiceFee({ job });
  const payoutSummary = getPayoutBreakdown({ booking });

  const totalPayoutStyle = isServiceFeeNotFixed
    ? { ...styles.title, ...styles.clickable }
    : styles.title;

  return (
    <Box>
      <SectionHeader title="Payout summary" />
      <Box style={styles.card} flexDirection={"column"}>
        {" "}
        {/* Changed from CardWrapper to Box */}
        <Box display={"flex"} flexDirection={"column"} gridGap={"12px"} padding={"32px 32px 0px"}>
          {payoutSummary.map(
            ({ label, value, currencySymbol, details, text }: ProPayoutBreakdown) => (
              <PayoutSummaryItem
                label={label}
                value={value}
                text={text}
                details={details}
                onClick={showBreakdownDetail}
                currencySymbol={currencySymbol}
              />
            )
          )}
        </Box>
        <Box padding={"0px 16px 30px"}>
          {checkIfEmpty(proPayouts) ? (
            <JumboTron>
              <Box style={{ ...styles.wrapper, flexDirection: "row" }}>
                <Box style={styles.title}>Total payout</Box>
                <Box style={totalPayoutStyle} onClick={showTotalPayoutDetail}>
                  {serviceFee}
                </Box>
              </Box>
            </JumboTron>
          ) : (
            <Accordion
              containerStyle={{
                padding: Spacing.S4,
              }}
              header={
                <Box style={{ ...styles.wrapper, flexDirection: "row" }}>
                  <Box style={styles.title}>Total payout</Box>
                  <Box style={totalPayoutStyle} onClick={showTotalPayoutDetail}>
                    {serviceFee}
                  </Box>
                </Box>
              }
            >
              <PayoutTimeline proPayouts={proPayouts} />
            </Accordion>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    lineHeight: "27px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "18px",
    fontWeight: 700,
  },
  payoutText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
  },
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  card: {
    gridGap: "24px",
    display: "flex",
    marginTop: "16px",
    borderRadius: "6px",
    border: `solid 1px ${Colors.PaleLilac}`,
    backgroundColor: "white",
  },
};

export default PayoutSummary;
