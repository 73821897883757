import { Box } from "@material-ui/core";
import React, { useEffect, useState, useRef,useCallback } from "react";
import { Button, Typography } from "@mui/material";
import AddressAutocomplete from "../../AddressAutocompleteV2";
import { dropdownStylesCombined } from "../../Dropdown";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LetterSpacing,
  LineHeight,
  Spacing,
} from "../../v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import RadioButton from "../../RadioButton";
import { SetLocationModal } from "../../Modals/MapModal/SetLocationModal";
import AddressLocationPin from "../../../images/map-pin-input-icon.svg";
import { useMobile, useTab } from "../../../hooks/mobile";
import { isMobile } from "react-device-detect";
import Dialog from "../../Dialog";
import CaretLeft from "../../../images/24_carat-left.png";
import { Text } from "../../v2/Styled";
import Divider from "../../Divider";

export const DistanceModal = ({
  selectedDistance,
  handleFilterApplied,
  setShowLocationFilter,
  setDistance,
}: {
  selectedDistance: any;
  handleFilterApplied: any;
  setShowLocationFilter: any;
  setDistance: any;
}) => {
  const distanceOptions = [
    {
      title: "20km",
      value: 20,
    },
    {
      title: "30km",
      value: 30,
    },
    {
      title: "50km",
      value: 50,
    },
    {
      title: "100km+",
      value: 100,
    },
  ];
  return (
    <>
      <Box display={"flex"} flexDirection={"column"} >
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: Spacing.S4,
              fontFamily: FontFamily.Museo,
              color: Colors.Dusk,
              fontWeight: FontWeight.Bold,
              fontSize: FontSize.F16,
              lineHeight: LineHeight.L24,
            }}
          >
            Distance
          </Typography>
        </Box>
        <Box display={"flex"} gridGap={Spacing.S6}>
          {distanceOptions.map((distance) => (
            <Box display={"flex"} key={distance.title}>
              <RadioButton
                style={{ height: 16, width: 16, marginRight: "8px" }}
                selected={distance.value === selectedDistance}
                onSelected={() => setDistance(distance.value)}
              />
              <Box
                color={Colors.Dusk}
                fontSize="16px"
                fontFamily="Museo"
                className="cursor-pointer"
                onClick={() => setDistance(distance.value)}
              >
                {distance.title}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export const LocationFilterModal = ({
  filter,
  handleFilterApplied,
  setShowLocationFilter,
}: {
  filter: any;
  handleFilterApplied: any;
  setShowLocationFilter: any;
}) => {
  let location;
  if (filter && filter.location && typeof filter.location === "string") {
    location = JSON.parse(filter.location);
  }
  const [selectedDistance, setSelectedDistance] = useState(filter.distance || 100);
  const [selectedAddress, setSelectedAddress] = useState(filter.location || null);
  const [fullAddress, setFullAddress] = useState<string | undefined>(location?.fullAddress || "");
  const [isMapSelected, setIsMapSelected] = useState(false);
  const handleClear = () => {
    filter.location = null;
    filter.distance = 100;
    setFullAddress("");
    handleFilterApplied(["location", "distance"], [null, null]);
    setShowLocationFilter(false);
  };

  const applyFilters = () => {
    if (selectedAddress && selectedDistance) {
      handleFilterApplied(["location", "distance"], [selectedAddress, selectedDistance]);
    } else if (selectedAddress) {
      handleFilterApplied("location", selectedAddress);
    } else if (selectedDistance) {
      handleFilterApplied("distance", selectedDistance);
    }
    setShowLocationFilter(false);
  };
  const [open, setOpen] = useState(true);
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setShowLocationFilter(false); 
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowLocationFilter]);

  return (
    <>
      {isMobile ? (
        <>
          <Dialog open={open} fullScreen={true}>
            <Box bgcolor={Colors.White} padding={Spacing.S6} height="100%" position="relative">
              <Box
                display={Display.Flex}
                flexDirection={FlexDirection.Column}
                height="100%"
                gridColumnGap={Spacing.S4}
              >
                <Box
                  height="52px"
                  display={Display.Flex}
                  justifyContent={JustifyContent.start}
                  alignItems={AlignItems.center}
                  onClick={() => {
                    setOpen(!open);
                    applyFilters();
                  }}
                  marginBottom={Spacing.S4}
                >
                  <img
                    src={CaretLeft}
                    alt={CaretLeft}
                    style={{ marginLeft: "-7px", width: "32px", height: "32px" }}
                  />
                </Box>
                <Box height="73px">
                  <AddressAutocomplete
                    title={"Address"}
                    placeholder="Enter address here to search"
                    locationType={null}
                    value={fullAddress || ""}
                    onChange={(text) => setFullAddress(text)}
                    onSelectedAddress={(address) => {
                      setFullAddress(address.fullAddress);
                      setSelectedAddress(address);
                    }}
                    onMapSelected={() => {
                      setIsMapSelected(true);
                    }}
                    textFieldStyles={{
                      border: "none",
                      borderBottom: "2px solid #DCDFE6",
                      borderRadius: "0px",
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding={Spacing.S6}
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  bgcolor={Colors.White}
                  boxShadow={`0 -1px 5px ${Colors.Grey}`}
                  zIndex={1}
                  paddingX={Spacing.S6}

                >
                  <Button
                    variant="text"
                    sx={{
                      fontFamily: FontFamily.Museo,
                      color: filter.location || filter.distance ? Colors.TextDanger : Colors.Grey,
                      textTransform: "none",
                      fontWeight: FontWeight.Bold,
                    }}
                    onClick={handleClear}
                  >
                    Clear all
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: Colors.TurquoiseBlue,
                      fontFamily: FontFamily.Museo,
                      color: Colors.White,
                      textTransform: "none",
                      fontWeight: FontWeight.Bold,
                      "&:hover": {
                        backgroundColor: Colors.TurquoiseBlue,
                        boxShadow: "none",
                      },
                      boxShadow: "none",
                    }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Box>
          </Dialog>
        </>
      ) : (
          
        <div ref={modalRef}>
          <Box
            position="absolute"
            style={{ ...dropdownStylesCombined, marginTop: Spacing.S4 }}
            minWidth={400}
          >
            <Box padding={Spacing.S6}>
              {/* Location Section */}
              <AddressAutocomplete
                title={"Location"}
                placeholder="Search for suburb or address"
                locationType={null}
                value={fullAddress || ""}
                onChange={(text) => setFullAddress(text)}
                onSelectedAddress={(address) => {
                  setFullAddress(address.fullAddress);
                  setSelectedAddress(address);
                }}
                onMapSelected={() => {
                  setIsMapSelected(true);
                }}
              />

              {/* Distance Section */}
              <DistanceModal
                selectedDistance={selectedDistance}
                handleFilterApplied={handleFilterApplied}
                setShowLocationFilter={setShowLocationFilter}
                setDistance={setSelectedDistance}
              />
            </Box>
            <Divider marginTop={Spacing.S2}/>
            {/* Action Buttons */}
            <Box display="flex" justifyContent="space-between"  padding={Spacing.S6}>
              <Button
                variant="text"
                sx={{
                  fontFamily: FontFamily.Museo,
                  color: filter.location || filter.distance ? Colors.TextDanger : Colors.Grey,
                  textTransform: "none",
                  fontWeight: FontWeight.Bold,
                }}
                onClick={handleClear}
              >
                Clear all
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: Colors.TurquoiseBlue,
                  fontFamily: FontFamily.Museo,
                  color: Colors.White,
                  textTransform: "none",
                  fontWeight: FontWeight.Bold,
                  "&:hover": {
                    backgroundColor: Colors.TurquoiseBlue,
                    boxShadow: "none",
                  },
                  boxShadow: "none",
                }}
                onClick={applyFilters}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </div>
      
      )}
      <SetLocationModal
        open={isMapSelected}
        onClose={() => {
          setIsMapSelected(false);
        }}
        setIsMapSelected={setIsMapSelected}
        handleAddressSelected={({ address }) => {
          setFullAddress(address.fullAddress || "");
          handleFilterApplied("location", address);
        }}
      />
    </>
  );
};


export default function LocationFilter({
  filter,
  handleFilterApplied,
  stylesProps,
  setCloseOthers,
  closeOthers,
}: {
  filter: any;
  handleFilterApplied: any;
  stylesProps?: React.CSSProperties;
  setCloseOthers?: any;
  closeOthers?: boolean | string;
}) {
  const [showLocationFilter, setShowLocationFilter] = useState(false);

  const isMobile = useMobile();

  useEffect(() => {
    if (closeOthers !== "LocationFilter") {
      setShowLocationFilter(false);
    }
  }, [closeOthers]);

  const getLocationText = (loc: any) => {
    let location = loc;
    if (typeof location === "string") {
      location = JSON.parse(loc);
    }
    return location.fullAddress;
  };

  const toggleModal = () => {
    setShowLocationFilter((prev) => !prev); 
    if (setCloseOthers) {
      setCloseOthers("LocationFilter");
    }
  };

  return (
    <Box position="relative">
      <Box maxWidth="450px">
        <Button
          variant={isMobile ? "text" : "outlined"}
          startIcon={
            isMobile ? undefined : (
              <img
                src={AddressLocationPin}
                color={Colors.Grey}
                style={{ color: Colors.Grey }}
                alt="location pin"
              />
            )
          }
          title={
            filter.location
              ? typeof filter.location === "string"
                ? JSON.parse(filter.location).fullAddress
                : filter.location.fullAddress
              : "Suburb"
          }
          sx={{
            height: Spacing.S11,
            width: "100%",
            minWidth: "20%",
            fontFamily: FontFamily.Museo,
            fontSize: FontSize.F16,
            fontWeight: FontWeight.Medium,
            color: filter.location ? Colors.TurquoiseBlue : Colors.Grey,
            borderColor: Colors.LightBlueGrey,
            padding: "0px 24px",
            borderRadius: "100px",
            textTransform: "none",
            textAlign: "left",
            justifyContent: "left",
            border: isMobile? undefined : "1px solid #DCDFE6",
            "&:hover": {
              borderColor: Colors.LightPeriwinkle,
              backgroundColor: Colors.CornflowerBlue,
            },
            "&:focus-visible": {
              borderColor: Colors.DarkSteelGrey,
              color: Colors.DarkSteelGrey,
            },
            ...stylesProps,
          }}
          onClick={toggleModal} 
        >
          <Text
            sx={{
              fontFamily: FontFamily.Museo,
              fontSize: FontSize.F16,
              fontWeight: FontWeight.Medium,
              color: filter.location ? Colors.TurquoiseBlue : Colors.Grey,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {filter.location
              ? getLocationText(filter.location)
              : isMobile
                ? "Enter suburb or address"
                : "Suburb"}
          </Text>
        </Button>
      </Box>

      {showLocationFilter && (
        <LocationFilterModal
          filter={filter}
          handleFilterApplied={handleFilterApplied}
          setShowLocationFilter={setShowLocationFilter}
        />
      )}
    </Box>
  );
}
