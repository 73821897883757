import { Stripe, StripeElements, StripeError, Token } from "@stripe/stripe-js";
import { User } from "../../../models";
import { getValue } from "../../../utils/object";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { DEFAULT_COUNTRY } from "../../../utils/country";
import { COUNTRIES_CURRENCY } from "../../../constants/countries";
import * as stripeTransformerService from "./stripeTransformer.service";
import { LOG_CHANNEL, sendErrorLog } from "../../log/log.service";
import { StripeClientErrorType } from "../../../constants/stripe";

interface options {
  stripe: Stripe | null;
  elements: StripeElements | null;
  user: User | null;
  forPayment?: boolean;
  onError: (m: string) => unknown;
  clientSecret: string | null;
  createSetupIntent: () => unknown;
  onTokenCreated: (
    e: MouseEvent,
    token: Token | undefined,
    cb: (e: MouseEvent, d: any) => unknown
  ) => void;
}

const handleError = (error: StripeError) => {
  if (error.type !== StripeClientErrorType.StripeInvalidRequestError) {
    sendErrorLog({
      domain: "STRIPE_CARD_ELEMENT",
      log: {
        title: "Error creating stripe card token",
        message: getValue(error, "message"),
        data: error,
      },
      channel: LOG_CHANNEL.SLACK,
    });
  }

  return error.type === StripeClientErrorType.StripeCardError
    ? error.message || ""
    : "Please check your card details.";
};

const createNewCard = async (
  e: MouseEvent,
  cb = (e: MouseEvent, d: any): any => {},
  {
    user,
    stripe,
    elements,
    onError,
    forPayment,
    clientSecret,
    createSetupIntent,
    onTokenCreated,
  }: options
) => {
  e.preventDefault();

  if (!stripe || !elements) {
    return;
  }

  const cardElement = elements.getElement(CardNumberElement);
  if (!cardElement) {
    return;
  }

  const userCountry = getValue(user, "country");
  const data = forPayment
    ? { address_country: userCountry }
    : {
        currency: COUNTRIES_CURRENCY[userCountry] || COUNTRIES_CURRENCY[DEFAULT_COUNTRY],
      };

  const { token, error: tokenError } = await stripe.createToken(cardElement, data);

  if (tokenError) {
    const msg = handleError(tokenError);
    onError(msg);
    return;
  }

  if (forPayment) {
    let stripeClientSecret = clientSecret as string;
    if (!stripeClientSecret) {
      await createSetupIntent();
    }

    const cardData = stripeTransformerService.getConfirmCardSetupData({
      user,
      token,
    });
    const { setupIntent, error: confirmError } = await stripe.confirmCardSetup(
      stripeClientSecret,
      cardData
    );

    if (confirmError) {
      handleError(confirmError);
      return;
    }

    const paymentMethodId = getValue(setupIntent, "payment_method");
    onTokenCreated(e, paymentMethodId, cb);
  } else {
    onTokenCreated(e, token, cb);
  }
};

export { createNewCard };
