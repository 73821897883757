import { hashData } from "../../../utils/encrypt.util";
import { environment } from "../../../constants/common";

function trackPinterest(data: any, eventName: string) {
  const payload = data
    ? {
        value: data.value,
        currency: data.currency,
        email: data.email,
        phone: data.phone_number,
      }
    : {};
  switch (eventName) {
    case "PageView":
      //@ts-ignore
      pintrk("track", "pagevisit");
      break;
    case "AddToCart":
      //@ts-ignore
      pintrk("track", "addtocart", payload);
      break;
    case "InitiateCheckout":
      //@ts-ignore
      pintrk("track", "initcheckout", payload);
      break;
    case "Purchase":
      //@ts-ignore
      pintrk("track", "checkout", payload);
      break;
  }
}

function trackTwitter(data: any, eventName: string) {
  const payload = data
    ? {
        value: data.value,
        currency: data.currency,
        email_address: data.email,
        phone_number: data.phone_number,
      }
    : {};
  switch (eventName) {
    case "PageView":
      //@ts-ignore
      // Insert Twitter Event ID page view
      twq("event", "tw-p049g-p2581", {});
      break;
    case "AddToCart":
      //@ts-ignore
      // Insert Twitter Event ID AddToCart
      twq("event", "tw-p049g-p258f", payload);
      break;
    case "InitiateCheckout":
      //@ts-ignore
      // Insert Twitter Event ID InitiateCheckout
      twq("event", "tw-p049g-p2589", payload);
      break;
    case "Purchase":
      //@ts-ignore
      // Insert Twitter Event ID Purchase
      twq("event", "tw-p049g-p0ns2", payload);
      break;
  }
}

async function trackTiktok(data: any, eventName: string) {
  let payload = {};
  if (data) {
    const email = data.email ? await hashData(data.email) : null; // Hashed email
    const phone_number = data.phone_number ? await hashData(data.phone_number) : null;
    payload = {
      value: data.value,
      currency: data.currency,
      content_type: "product",
      content_id: data.product_id,
      contents: data.products.map((product: any) => {
        return {
          content_id: product.id,
          content_name: product.name,
          quantity: product.quantity,
          price: product.price,
        };
      }),
      external_id: {
        email, // Hashed email
        phone_number, // Hashed phone number
      },
    };
  }
  if (eventName === "PageView") {
    // @ts-ignore
    window.ttq.page();
  } else if (eventName === "Purchase") {
    //@ts-ignore
    window.ttq.track("PlaceAnOrder", payload);
  } else {
    //@ts-ignore
    ttq.track(eventName, payload);
  }
}

async function trackSnapchat(data: any, eventName: string) {
  let payload = {};
  if (data) {
    const email = data.email ? await hashData(data.email) : null; // Hashed email
    const phone_number = data.phone_number ? await hashData(data.phone_number) : null;
    payload = {
      value: data.value,
      currency: data.currency,
      email,
      phone_number,
    };
  }
  switch (eventName) {
    case "PageView":
      //@ts-ignore
      snaptr("track", "PAGE_VIEW", {});
      break;
    case "AddToCart":
      //@ts-ignore
      snaptr("track", "ADD_CART", payload);
      break;
    case "InitiateCheckout":
      //@ts-ignore
      snaptr("track", "INITIATE_CHECKOUT", payload);
      break;
    case "Purchase":
      //@ts-ignore
      snaptr("track", "PURCHASE", payload);
      break;
  }
}

async function trackSpotify(data: any, eventName: string) {
  let payload = {};
  if (data) {
    const email = data.email ? await hashData(data.email) : null; // Hashed email
    const phone_number = data.phone_number ? await hashData(data.phone_number) : null;
    payload = {
      value: data.value,
      currency: data.currency,
    };
  }
  switch (eventName) {
    case "PageView":
      //@ts-ignore
      window.spdt("view");
      break;
    case "AddToCart":
      //@ts-ignore
      window.spdt("addtocart", payload);
      break;
    case "InitiateCheckout":
      //@ts-ignore
      window.spdt("checkout", payload);
      break;
    case "Purchase":
      //@ts-ignore
      window.spdt("purchase", payload);
      break;
  }
}

const trackEvent = async (eventName: string, data: any, source: any) => {
  const isProduction = environment?.isProduction;
  try {
    if (isProduction) {
      //for facebook events
      //@ts-ignore
      if (window.fbq && source === "facebook") {
        //@ts-ignore
        window.fbq("track", eventName, data);
        // console.log(`Tracking ${eventName} from ${source}:`, data);
      }
      //for tiktok events
      // @ts-ignore
      if (window.ttq && (source === "tiktok" || source === "Tiktok")) {
        trackTiktok(data, eventName);
        // console.log(`Tracking ${eventName} from ${source}:`, data);
      }

      //for twitter events
      // @ts-ignore
      if (isProduction && window.twq && source === "twitter") {
        trackTwitter(data, eventName);
      }

      //for pinterest events
      //@ts-ignore
      if (isProduction && window.pintrk && source === "pinterest") {
        trackPinterest(data, eventName);
      }

      //for snapchat events
      //@ts-ignore
      if (isProduction && window.snaptr && source === "snapchat") {
        //@ts-ignore
        trackSnapchat(data, eventName);
      }

      //for spotify events
      //@ts-ignore
      if (isProduction && window.spdt && source === "spotify") {
        //@ts-ignore
        trackSpotify(data, eventName);
      }
      console.log(`Tracking ${eventName} from ${source}:`, data);
    }
  } catch (err) {
    console.log("error here>>>", err);
  }
};

const trackPageView = (source: any) => {
  trackEvent("PageView", null, source);
};

const trackAddToCart = (data: any, source: any) => {
  trackEvent("AddToCart", data, source);
};

const trackInitiateCheckout = (data: any, source: any) => {
  trackEvent("InitiateCheckout", data, source);
};

const trackPurchase = (data: any, source: any) => {
  trackEvent("Purchase", data, source);
};

const pixelEvents = {
  trackPageView,
  trackAddToCart,
  trackInitiateCheckout,
  trackPurchase,
};

export default pixelEvents;
