import React from "react";
import { Box } from "@material-ui/core";
import axios from "axios";
import braintree from "braintree-web";
import moment from "moment";
import { Colors } from "../../constants/colors";
import { useAccessToken } from "../../hooks/common";
import HeaderIcon from "../../images/payment-section-paypal.svg";
import { PaymentMethod } from "../../models";
import { usePaymentStore } from "../../stores/booking";
import { useBraintreeStore } from "../../stores/braintree";
import Button, { ButtonType } from "../Button";
import RadioButton from "../RadioButton";
import PaymentFrameItem from "./PaymentFrameItem";
import queryClient from "../../config/queryClient";
import { PaymentType } from "../../constants/payment";
import { PAYMENT_KEYS, usePaymentMethods } from "../../hooks/payment.hooks";

interface Props {
  selected: boolean;
  onSelected: () => unknown;
}

export default function PaymentFramePaypal({ selected, onSelected }: Props) {
  const accessToken = useAccessToken();

  const { data: paymentMethodsData, isLoading: isPaymentMethodsLoading } = usePaymentMethods(
    accessToken || ""
  );
  const _paymentMethods =
    paymentMethodsData && !isPaymentMethodsLoading ? (paymentMethodsData as PaymentMethod[]) : [];

  const paymentMethods = _paymentMethods
    .filter((method) => method.type === "paypal")
    .sort((a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix());

  const { paymentMethod, setPaymentMethod, paymentMethodByType, paymentType } = usePaymentStore();
  const { paypalInstance, setPaypalInstance, clientToken, fetchClientToken } = useBraintreeStore();

  React.useEffect(() => {
    if (!clientToken) {
      fetchClientToken();
    }
  }, []);

  const onPaypal = () => {
    paypalInstance?.tokenize({ flow: "vault" }, (tokenizeErr, payload) => {
      if (tokenizeErr) {
        console.debug("error: ", tokenizeErr);
        return;
      }

      console.debug("payload: ", payload);

      if (payload) {
        const { nonce, details } = payload;
        const { email } = details;

        axios
          .post("/paymentmethods", {
            accessToken,
            nonce,
            email,
          })
          .then((response) => {
            console.debug("added: ", response);

            const paymentMethod = response.data as PaymentMethod;

            queryClient.invalidateQueries({ queryKey: [PAYMENT_KEYS.GET_PAYMENT_METHODS] });
            setPaymentMethod(paymentMethod);
          })
          .catch((error) => {
            console.debug("add payment method error: ", error);
          });
      }
    });
  };

  React.useEffect(() => {
    clientToken &&
      braintree.client.create(
        {
          authorization: clientToken,
        },
        (error, clientInstance) => {
          // create paypal instance
          setPaypalInstance(clientInstance);

          braintree.paypal.create({ client: clientInstance }, (paypalErr, paypalInstance) => {
            // setPaypalLoading(false);

            if (!paypalErr && paypalInstance) {
              setPaypalInstance(paypalInstance);
            }
          });
        }
      );
  }, [clientToken]);

  const handleSelected = () => {
    onSelected();
    if (paymentMethods && paymentMethods.length === 1) {
      setPaymentMethod(paymentMethods[0]);
    } else if (paymentType !== PaymentType.paypal) {
      // select last payment selected by user if payment type is changed
      setPaymentMethod(paymentMethodByType[PaymentType.paypal] || null);
    }
  };

  return (
    <>
      <Box border="1px solid #C5CBD6" borderRadius="8px" overflow="hidden" mt="16px">
        <Box
          height="56px"
          alignItems="center"
          bgcolor={Colors.PaleGrey}
          display="flex"
          flexDirection="row"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between" flex={1}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box width="8px" />
              <RadioButton selected={selected} onSelected={handleSelected} />
              <img src={HeaderIcon} />
            </Box>
          </Box>
        </Box>

        {selected && (
          <Box display="flex" flexDirection="column" style={{ background: Colors.White }}>
            {paymentMethods.map((method: PaymentMethod) => (
              <PaymentFrameItem
                paymentMethod={method}
                onSelected={() => {
                  onSelected();
                  setPaymentMethod(method);
                }}
                selected={paymentMethod?.id === method.id}
              />
            ))}

            <Box m={2} mt={1}>
              <Button title="Add PayPal account" type={ButtonType.outlined} onClick={onPaypal} />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
