import { Box } from "@material-ui/core";
import CardWrapper from "../../../components/BookingDetails/BookingDetailCard";
import Button, { ButtonType } from "../../../components/Button";

interface Props {
  onBookingComplete: () => unknown;
  onAbandon: () => unknown;
  onBookingUpdate: (updated: any) => unknown;
  allowBookingUpdate: boolean;
  disabled: boolean;
  CanChatBtn: React.ReactElement | null;
}

const OnMyWayJobActions = ({
  onAbandon,
  onBookingUpdate,
  allowBookingUpdate,
  onBookingComplete,
  disabled,
  CanChatBtn,
}: Props) => {
  return (
    <CardWrapper>
      <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <Button
            title="Message Client"
            type={ButtonType.secondary}
            onClick={() => {}}
            disabled={disabled}
          />
          <Button
            title="Call Client"
            type={ButtonType.secondary}
            onClick={() => {}}
            disabled={disabled}
          />
        </Box> */}
        {
          <Box mt="10px">
            {CanChatBtn}

            {allowBookingUpdate ? (
              <Box mt="24px">
                <Button
                  title="Update booking"
                  type={ButtonType.outlined}
                  onClick={onBookingUpdate}
                  disabled={disabled}
                />
              </Box>
            ) : (
              <></>
            )}

            <Box mt="24px">
              <Button
                title="Complete"
                type={ButtonType.outlined}
                onClick={onBookingComplete}
                disabled={disabled}
              />
            </Box>

            <Box mt="24px">
              <Button
                title="Cancel"
                type={ButtonType.danger}
                onClick={onAbandon}
                disabled={disabled}
              />
            </Box>
          </Box>
        }
      </Box>
    </CardWrapper>
  );
};

export default OnMyWayJobActions;
