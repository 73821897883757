import React, { useRef } from "react";
import { Box } from "@material-ui/core";
import { Token } from "@stripe/stripe-js";
import moment from "moment";
import Each from "../Each";
import RadioButton from "../RadioButton";
import * as Styled from "../v2/Styled/enum";
import { PaymentMethod } from "../../models";
import Button, { ButtonType } from "../Button";
import { Colors } from "../../constants/colors";
import { useUserStore } from "../../stores/user";
import { PaymentFrameType } from "./PaymentFrame";
import PaymentFrameItem from "./PaymentFrameItem";
import queryClient from "../../config/queryClient";
import { useAccessToken } from "../../hooks/common";
import { PaymentType } from "../../constants/payment";
import { usePaymentStore } from "../../stores/booking";
import PaymentCards from "../../images/payment-cards.png";
import AddNewCardModal from "../Payment/AddNewCardModal";
import { checkIfEmpty, getValue } from "../../utils/object";
import AddNewCardModelStripe from "../Payment/AddNewCardModelStripe";
import StripeCardAddPayment from "../Stripe/Card/StripeCardAddPayment";
import { StripeCardFormHandle } from "../Stripe/Card/StripeCardElement";
import { PAYMENT_KEYS, useAllowedPayments, usePaymentMethods } from "../../hooks/payment.hooks";

interface Props {
  type?: PaymentFrameType;
  selected: boolean;
  onSelected: () => unknown;
  bypassCardAddUI?: boolean;
}

export default function PaymentFrameCards({
  selected,
  onSelected,
  bypassCardAddUI = false,
}: Props) {
  const accessToken = useAccessToken();
  const cardFormRef = useRef<StripeCardFormHandle | null>(null);

  const { user } = useUserStore();
  const { isStripeAllowed } = useAllowedPayments(user);

  const { data: paymentMethodsData, isLoading: isPaymentMethodsLoading } = usePaymentMethods(
    accessToken || ""
  );

  const _paymentMethods =
    paymentMethodsData && !isPaymentMethodsLoading ? (paymentMethodsData as PaymentMethod[]) : [];

  const paymentMethods = _paymentMethods
    .filter((method) => ["card", "google-pay"].includes(method.type))
    .sort((a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix());

  const { paymentType, paymentMethod, setPaymentMethod, paymentMethodByType } = usePaymentStore();

  const [addNewCardOpen, setAddNewCardOpen] = React.useState(false);

  const noCards = checkIfEmpty(paymentMethods);

  const handleSelected = () => {
    onSelected();

    const selectPayment = (payment: PaymentMethod | null) => {
      if (!payment || getValue(payment, "isExpired", false)) return;
      setPaymentMethod(payment);
    };

    if (paymentMethods && paymentMethods.length === 1) {
      selectPayment(paymentMethods[0]);
    } else if (paymentType !== PaymentType.card) {
      selectPayment(paymentMethodByType[PaymentType.card] || null);
    }
  };

  const onAddCard = (e: MouseEvent, paymentMethodId: Token | string | undefined, cb: any) => {};

  return (
    <>
      <Box border="1px solid #C5CBD6" borderRadius="8px" overflow="hidden">
        <Box
          height="56px"
          alignItems="center"
          bgcolor={Colors.PaleGrey}
          display="flex"
          flexDirection="row"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between" flex={1}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box width="8px" />
              <RadioButton selected={selected} onSelected={handleSelected} />
              <Box
                fontFamily="Museo"
                fontWeight={600}
                fontSize="16px"
                lineHeight="24px"
                color={Colors.Dusk}
              >
                Credit/debit card
              </Box>
            </Box>
            <Box mr={2}>
              <img src={PaymentCards} height="24px" />
            </Box>
          </Box>
        </Box>

        {selected ? (
          !noCards ? (
            <Box display="flex" flexDirection="column">
              <Each
                of={paymentMethods}
                render={(method: PaymentMethod) => (
                  <PaymentFrameItem
                    paymentMethod={method}
                    onSelected={() => {
                      onSelected();
                      setPaymentMethod(method);
                    }}
                    selected={paymentMethod?.id === method.id}
                  />
                )}
              />

              <Box m={2} mt={1}>
                <Button
                  title="Add credit or debit card"
                  type={ButtonType.outlined}
                  onClick={() => setAddNewCardOpen(true)}
                />
              </Box>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" padding={Styled.Spacing.S4}>
              <StripeCardAddPayment
                ref={cardFormRef}
                onSuccess={onAddCard}
                bypassCardAddUI={bypassCardAddUI}
              />
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
      {user && isStripeAllowed ? (
        <>
          <AddNewCardModelStripe
            cardOnly
            open={addNewCardOpen}
            onClose={() => setAddNewCardOpen(false)}
            onAddedNewCard={(method) => {
              setAddNewCardOpen(false);
              setPaymentMethod(method);
              queryClient.invalidateQueries({ queryKey: [PAYMENT_KEYS.GET_PAYMENT_METHODS] });
            }}
          />
        </>
      ) : (
        <>
          <AddNewCardModal
            cardOnly
            open={addNewCardOpen}
            onClose={() => setAddNewCardOpen(false)}
            onAddedNewCard={(method) => {
              setAddNewCardOpen(false);
              setPaymentMethod(method);
              queryClient.invalidateQueries({ queryKey: [PAYMENT_KEYS.GET_PAYMENT_METHODS] });
            }}
          />
        </>
      )}
    </>
  );
}
