import { AxiosError } from "axios";
import { ErrorResponse } from "../models";
import { getValue } from "../utils/object";

export function parseApiError(error: AxiosError) {
  const errorMessage: any =
    (error.response?.data as ErrorResponse)?.errorMessage ||
    (error.response?.data as ErrorResponse)?.errorMessage ||
    (error.response?.data as ErrorResponse)?.error?.message ||
    error.message ||
    "An unknown error has occured";

  return errorMessage.message || errorMessage; // added to handle forbidden error message due to rate limiter.
}

export function parseAfterpayAccessError(error: AxiosError) {
  return {
    message:
      error.response?.data?.errorMessage ||
      error.response?.data?.error.message ||
      "An unknown error has occured",
    userDetails: error.response?.data.userDetails,
  };
}

const parseApiV2Error = (error: AxiosError) => {
  return (error.response?.data as ErrorResponse)?.error?.message || "An unknown error has occured";
};

const ERROR_ORDER = {
  DETAIL: "detail",
  RESPONSE: "response",
  GENERIC: "generic",
};

const parseApiErrorFromResponse = ({
  error,
  order = [ERROR_ORDER.DETAIL, ERROR_ORDER.RESPONSE, ERROR_ORDER.GENERIC],
}: {
  error: AxiosError;
  order?: Array<string>;
}) => {
  const errorMessagesFrom: Record<string, any> = {
    detail: getValue(error, "response.data.error.details[0].message") || "",
    response: getValue(error, "response.data.error.message") || "",
    generic: getValue(error, "message") || "",
  };

  return (
    (order || []).map((key) => errorMessagesFrom[key]).find((msg) => msg) || "Something went wrong."
  );
};

export { parseApiV2Error, parseApiErrorFromResponse, ERROR_ORDER };
