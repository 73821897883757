import { ABANDON_REASON } from "../../constants/prodashboard";
import { JobStatus, BOOKING_STATUS } from "../../constants/booking";
import { TherapistBooking } from "../../models";
import moment from "moment";
import { getTimeOfArrivalWithGraceTime } from "../../utils/date.util";

export const isBookingAbandonAllowed = (booking: TherapistBooking | undefined, reason: string) => {
  switch (reason) {
  case ABANDON_REASON.CLIENT_NO_SHOW:
    return clientNoShow(booking);

  case ABANDON_REASON.CLIENT_PAYMENT_STILL_PENDING:
    return isPaymentPending(booking);

  case ABANDON_REASON.PARTNER_PRO_NOT_CONFIRMED:
    return isPartnerProNotConfirmed(booking);

  default:
    return {
      title: "",
      body: "",
      allowAbandon: true,
    };
  }
};

// CLIENT_NO_SHOW
const clientNoShow = (booking: TherapistBooking | undefined) => {
  const timeOfArrival = booking?.bookingdetail.booking?.timeOfArrival;
  const timeOfArrivalMoment = moment(timeOfArrival).utc();
  if (bookingTooSoonToCancel(timeOfArrivalMoment)) {
    return {
      title: "Too soon to cancel",
      body: `Clients are given a 20min grace period for no-shows.\nPlease wait until ${getTimeOfArrivalWithGraceTime(
        timeOfArrival
      ).format("h:mma")} for your client to show up`,
      allowAbandon: false,
    };
  } else {
    return {
      allowAbandon: true,
    };
  }
};

const bookingTooSoonToCancel = (timeOfArrival: any) => {
  const timeOfArrivalWithGraceTime = getTimeOfArrivalWithGraceTime(timeOfArrival).utc();
  const now = moment.utc();
  return now.isBefore(timeOfArrivalWithGraceTime);
};

// CLIENT_PAYMENT_STILL_PENDING
const isPaymentPending = (booking: TherapistBooking | undefined) => {
  const bookings = booking?.bookingdetail?.booking;
  const isPaid = bookings?.isPaid;
  const bookingStatus = bookings?.status;

  const condition =
    (isPaid && bookingStatus === BOOKING_STATUS.ARRANGED) || bookingStatus === BOOKING_STATUS.NEW;

  if (condition) {
    return {
      title: "Payment already settled",
      body: "The payment for this booking is already settled, please choose another reason.",
      allowAbandon: false,
    };
  } else {
    return {
      allowAbandon: true,
    };
  }
};

// PARTNER_PRO_NOT_CONFIRMED
const isPartnerProNotConfirmed = (booking: TherapistBooking | undefined) => {
  const bookingDetails = booking?.bookingdetail?.booking?.bookingdetails;
  const anyJobPending = bookingDetails?.some((detail) => [JobStatus.rebooking,JobStatus.pending].includes(detail.job.status));
  const hasMoreThanOneJob = bookingDetails && bookingDetails?.length > 1;

  if (hasMoreThanOneJob && !anyJobPending) {
    return {
      title: "Booking Already Confirmed",
      body: "This booking is already confirmed, please choose another reason.",
      allowAbandon: false,
    };
  } else if (!hasMoreThanOneJob) {
    return {
      title: "Booking Already Confirmed",
      body: "This booking is already confirmed, please choose another reason.",
      allowAbandon: false,
    };
  } else {
    return {
      allowAbandon: true,
    };
  }
};
