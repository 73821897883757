import React from "react";

import { Box } from "@material-ui/core";
import CardWrapper from "../../../components/BookingDetails/BookingDetailCard";
import Button, { ButtonType } from "../../../components/Button";

interface Props {
  onBookingUpdate: (updated: any) => unknown;
  jobStatus?: string;
}

const CompletedJobActions = ({ onBookingUpdate, jobStatus }: Props) => {
  return (
    <CardWrapper>
      <Box>
        <Button title="Update booking" type={ButtonType.outlined} onClick={onBookingUpdate} />
      </Box>
    </CardWrapper>
  );
};

export default CompletedJobActions;
