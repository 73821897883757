import { useRef, useState } from "react";

import InitialModal from "./InitialModal";
import AddSignatureModal from "./addSignatureModal";

import { useUserStore } from "../../../../stores/user";
import { useAlertStore } from "../../../../stores/alert";
import { useConfirmAgreement, useSignature } from "../../../../hooks/signature.hook";

import { SIGNATURE_MODAL_TYPE } from "./data/signatureModal.enum";

interface Props {
  visible: boolean;
  onAcceptConfirmed: () => unknown;
  onClose: () => unknown;
  booking: {
    user: {
      id: number;
    };
    id: number;
  };
  job: {
    id: number;
  };
  type?: string;
}

enum modalContentType {
  initial,
  addSignature,
}

const ClientTermsAndPoliciesModal = ({
  job,
  type,
  visible,
  booking,
  onClose,
  onAcceptConfirmed,
}: Props) => {
  const [signatureMode, setSignatureMode] = useState<modalContentType>(modalContentType.initial);
  const sigCanvas = useRef<any>(null);

  const { user } = useUserStore();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { data: signature } = useSignature({ userId: user?.id as number });

  const { mutate: confirmAgreement, isLoading } = useConfirmAgreement({
    onSuccess: () => {
      setSuccessMessage("Document signed successfully.");
      onAcceptConfirmed();
    },
    onError: (err: any) => {
      setErrorMessage(err?.response?.data?.error?.message || "Failed to add signature.");
    },
  });

  const showAddSignatureModal = () => setSignatureMode(modalContentType.addSignature);
  const showInitialModal = () => setSignatureMode(modalContentType.initial);

  const handleConfirmTerms = (agreed?: boolean) => {
    if (!booking) return;
    if (!signature) {
      setErrorMessage("Please add your signature to proceed.");
      return;
    }

    if (!agreed) {
      setErrorMessage(
        "Please confirm that you agree with the client terms and conditions for all bookings from this client."
      );
      return;
    }

    const data = {
      userId: booking.user.id,
      signatureId: signature.id,
      bookingId: booking.id,
    };

    confirmAgreement(data);
  };

  const handleClose = () => {
    setSignatureMode(modalContentType.initial);
    if (!!onClose) onClose();
  };

  if (!visible) {
    return null;
  }

  const getModalDescription = () => {
    if (type === SIGNATURE_MODAL_TYPE.OFFER) {
      return "This client requires all providers to accept the client's specific terms and policies. To proceed with your alternate offer, please review and sign to confirm your agreement.";
    }
    return "This client requires all providers to accept the client's specific terms and policies.";
  };

  const signatureDescription = getModalDescription();

  return (
    <>
      <InitialModal
        visible={signatureMode === modalContentType.initial}
        signature={signature}
        openAddSignatureModal={showAddSignatureModal}
        onClose={handleClose}
        onConfirm={handleConfirmTerms}
        isLoading={isLoading}
        job={job}
        description={signatureDescription}
      />

      <AddSignatureModal
        goBack={showInitialModal}
        sigCanvas={sigCanvas}
        onClose={handleClose}
        visible={signatureMode === modalContentType.addSignature}
      />
    </>
  );
};

export default ClientTermsAndPoliciesModal;
