import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, CircularProgress } from "@material-ui/core";
import BookingsMapArea from "./BookingsMapArea";
import { getValue } from "../../../utils/object";
import { Colors } from "../../../constants/colors";
import { useUserStore } from "../../../stores/user";
import { useAccessToken } from "../../../hooks/common";
import { Job, TherapistBooking } from "../../../models";
import { ADDRESS_DISCLAIMER } from "../../../constants/address";
import BookingItem from "../../../components/ProDashboard/BookingItem";
import { useServiceArea } from "../../ProDashboard/ServiceArea/context/ServiceAreaState";
import serviceAreaAction from "../../ProDashboard/ServiceArea/context/ServiceAreaAction";
import { BOOKINGS_SECTION_VALUES } from "../../../constants/prodashboard";
import { Spacing } from "../../../components/v2/Styled/enum";
import { Paths } from "../../../constants/paths";
import { isNil } from "lodash";
import LoginModal from "../../Login/LoginModal";
import SignUpModal from "../../SignUp/SignUpModal";
import ForgotPasswordModal from "../../ForgotPassword/ForgotPasswordModal";
import { useAlertStore } from "../../../stores/alert";

interface BookingsMapProps {
  bookings?: TherapistBooking[];
  jobs?: Job[];
  tab: string;
}

const BookingsMap = ({ bookings, jobs, tab }: BookingsMapProps) => {
  const markerRef = useRef<any>(null);
  const accessToken = useAccessToken();
  const { state, dispatch } = useServiceArea();
  const { user, fetchMe, isFetchingUser } = useUserStore();
  const history = useHistory();

  const [bookingsList, setBookingsList] = useState<any[]>([]);
  const [selectedBooking, setSelectedBookings] = useState<any>(null);

  const { loading, serviceArea = {} } = state;

  const therapistLongitude = getValue(user, "therapistprofile.longitude");
  const therapistLatitde = getValue(user, "therapistprofile.latitude");
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [forgotPasswordPrefillEmail, setForgotPasswordPrefillEmail] = useState("");
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const serviceAreaInfo = {
    fixed: serviceArea.fixed,
    realTime: {
      ...serviceArea.realTime,
      latitude: therapistLatitde,
      longitude: therapistLongitude,
    },
  };

  useEffect(() => {
    if (accessToken) serviceAreaAction.fetchServiceArea(dispatch, accessToken);
    else serviceAreaAction.fetchServiceArea(dispatch, "");
  }, [accessToken]);

  useEffect(() => {
    if (bookings) {
      setBookingsList(bookings);
    }
    if (jobs) {
      setBookingsList(jobs);
    }
  }, [bookings, jobs]);

  const onBookingSelect = (booking: any) => {
    setSelectedBookings(booking);
  };

  const closeModal = (event: MouseEvent<HTMLElement>, cleanup = false) => {
    if (!cleanup) {
      event.stopPropagation();
    }
    setSelectedBookings(null);
    if (markerRef && markerRef.current) {
      markerRef.current.marker.setIcon(markerRef.current.icon);
    }
  };

  const handleClick = (jobId: any) => {
    if (tab === BOOKINGS_SECTION_VALUES.UPCOMING) return;
    if (user && user?.therapistprofile) {
      history.push(`/pro/bookings/${jobId}`);
    } else if (user && !user.therapistprofile) {
      history.push(Paths.ProSignup);
    } else {
      setLoginModalOpen(true);
    }
  };

  useEffect(() => {
    //console.log(`Is fetching user: ${isFetchingUser}`);
    if (user && !isFetchingUser && accessToken && selectedBooking) {
      // refreshJobDetails().then(() => {
      // eslint-disable-next-line no-eval
      handleClick(selectedBooking.id);
      // });
    }
  }, [user, isFetchingUser, accessToken]);

  return (
    <Box>
      <Box mb="24px" position="relative">
        {tab === BOOKINGS_SECTION_VALUES.NEW && (
          <Box
            fontFamily="Open Sans"
            fontSize="14px"
            lineHeight="21px"
            fontWeight={400}
            color={Colors.BlueyGrey}
            py={Spacing.S3}
            textAlign={"center"}
          >
            {ADDRESS_DISCLAIMER}
          </Box>
        )}
        {loading ? (
          <CircularProgress
            variant="indeterminate"
            thickness={4}
            size={25}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "45px",
              width: "100%",
            }}
          />
        ) : (
          <BookingsMapArea
            tab={tab}
            showServiceAreas
            id={"bookings-map"}
            markerRef={markerRef}
            bookings={bookingsList}
            closeBookingModal={closeModal}
            serviceAreaInfo={serviceAreaInfo}
            onBookingSelect={onBookingSelect}
          />
        )}

        {selectedBooking &&
          (tab === BOOKINGS_SECTION_VALUES.NEW ? (
            <BookingItem
              job={selectedBooking}
              onClick={({ jobId }) => {
                handleClick(jobId);
              }}
              isModal={true}
              onClose={closeModal}
            />
          ) : (
            <BookingItem job={selectedBooking} isModal={true} onClose={closeModal} />
          ))}
      </Box>
      {isNil(user) && (
        <LoginModal
          open={loginModalOpen}
          onClose={() => {
            setLoginModalOpen(false);
          }}
          onLoggedIn={() => {
            fetchMe();
            setLoginModalOpen(false);
          }}
          onForgotPassword={(email) => {
            setForgotPasswordPrefillEmail(email);
            setLoginModalOpen(false);
            setForgotPasswordModalOpen(true);
          }}
          onCreateAccount={() => {
            setLoginModalOpen(false);
            setSignUpModalOpen(true);
          }}
          redirect={false}
        />
      )}
      {isNil(user) && (
        <SignUpModal
          open={signUpModalOpen}
          onLogin={() => {
            setSignUpModalOpen(false);
            setLoginModalOpen(true);
          }}
          onCreatedAccount={() => {
            fetchMe();
            setSignUpModalOpen(false);
          }}
          onClose={() => setSignUpModalOpen(false)}
        />
      )}
      {isNil(user) && (
        <ForgotPasswordModal
          open={forgotPasswordModalOpen}
          onClose={() => setForgotPasswordModalOpen(false)}
          onBackToLogin={() => {
            setForgotPasswordModalOpen(false);
            setLoginModalOpen(true);
          }}
          onSentInstructions={(email: any) => {
            setForgotPasswordModalOpen(false);
            setSuccessMessage(`Password reset instructions have been sent to ${email}`);
          }}
          prefillEmail={forgotPasswordPrefillEmail}
        />
      )}
    </Box>
  );
};
export default BookingsMap;
