import Button, { ButtonType } from "../../Button";
import ContentModal from "../../Modals/ContentModal/contentModal";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from "../../v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import { Box, Button as ButtonOriginal } from "@mui/material";
import { useServiceRates } from "../../../hooks/services/rates.hooks";
import { checkIfEmpty } from "../../../utils/object";
import LocationFilter, { DistanceModal } from "./LocationFilter";
import ServicesFilter from "./ServicesFilter";
import DateRangeFilter from "./DateRangeFilter";
import { SortComponentModal } from "./SortComponent";
import { useEffect, useState } from "react";
import { DEFAULT_COUNTRY } from "../../../utils/country";
import { useUserStore } from "../../../stores/user";

interface Props {
  open: boolean;
  onClose: () => void;
  filter: any;
  handleFilter: (key: string, value: any) => void;
  clearFilter?: () => void;
}

export const FilterComponentResponsive = ({
  open,
  onClose,
  filter,
  handleFilter,
  clearFilter,
}: Props) => {
  const { user } = useUserStore();
  let countryCode = user?.country || DEFAULT_COUNTRY;
  if (filter.location) {
    countryCode =
      typeof filter.location === "string"
        ? JSON.parse(filter.location)?.countryCode
        : filter.location?.countryCode;
  }
  const { services } = useServiceRates(countryCode, false);
  const getServiceOptions = () => {
    if (checkIfEmpty(services)) return [];

    return services.map(({ alias, id }) => ({ value: id, title: alias }));
  };

  const serviceOptions = getServiceOptions();
  const [selectedDistance, setSelectedDistance] = useState(filter.distance || 100);

  useEffect(() => {
    // @ts-ignore
    handleFilter("distance", selectedDistance);
  }, [selectedDistance]);

  return (
    <ContentModal
      visible={open}
      onClose={onClose}
      actions={[
        <ButtonOriginal
          variant={"text"}
          sx={{
            fontFamily: FontFamily.Museo,
            color: filter.location || filter.distance ? Colors.TextDanger : Colors.Grey,
            textTransform: "none",
            fontWeight: FontWeight.Bold,
          }}
          onClick={() => {
            filter.order = null;
            handleFilter("serviceType", null);
            setSelectedDistance(null);
            if (clearFilter) {
              clearFilter();
              onClose();
            }
          }}
        >
          Clear all
        </ButtonOriginal>,
        <Button
          title="Apply"
          type={ButtonType.secondary}
          onClick={() => {
            handleFilter();
            onClose();
          }}
          width={"109px"}
        />,
      ]}
      showCrossIcon={true}
      title="Filter and sort"
      divider={true}
      maxWidth="md"
      PaperProps={{
        style: {
          minWidth: "100%",
          minHeight: "100%",
        },
      }}
      actionStyle={{
        paddingLeft: Spacing.S6,
        paddingRight: Spacing.S6,
        justifyContent: "space-between",
      }}
      titleStyle={{ fontSize: "16px", colors: Colors.Indigo }}
      childrenStyle={{ marginTop: 0 }}
      fixButtonsWithDivider={true}
    >
      <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
        <SortComponentModal
          filter={filter}
          handleFilterApplied={handleFilter}
          setShowSortModal={true}
        />
        <Box
          fontWeight={FontWeight.Bold}
          fontSize={FontSize.F20}
          color={Colors.NightBlue}
          fontFamily="Museo"
        >
          Filter by
        </Box>
        <Box display={Display.Flex} flexDirection={FlexDirection.Column} gap={Spacing.S4}>
          {/* Location */}
          <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
            <Box
              fontWeight={FontWeight.Bold}
              fontSize={FontSize.F16}
              fontFamily={FontFamily.Museo}
              color={Colors.DarkSteelGrey}
              marginY={Spacing.S2}
              lineHeight={LineHeight.L24}
            >
              Location
            </Box>
            <Box>
              <LocationFilter
                filter={filter}
                stylesProps={{
                  borderRadius: "0px",
                  borderBottom: "2px solid #DCDFE6",
                  width: "100%",
                  padding: "0px",
                  fontSize: FontSize.F16,
                }}
                handleFilterApplied={handleFilter}
              />
            </Box>
          </Box>

          {/* Distance */}
          <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
            <Box marginTop={Spacing.S2}>
              <DistanceModal
                handleFilterApplied={handleFilter}
                setShowLocationFilter={true}
                selectedDistance={selectedDistance}
                setDistance={setSelectedDistance}
              />
            </Box>
          </Box>

          {/* Service */}
          <Box marginBottom={Spacing.S2}>
            <Box
              fontWeight={FontWeight.Bold}
              fontSize={FontSize.F16}
              fontFamily={FontFamily.Museo}
              color={Colors.DarkSteelGrey}
              marginBottom={Spacing.S3}
            >
              Services
            </Box>
            <ServicesFilter
              filter={filter}
              handleFilterApplied={handleFilter}
              stylesProps={{
                borderRadius: "0px",
                borderBottom: "2px solid #DCDFE6",
                width: "100%",
                padding: "0px",
                fontSize: FontSize.F16,
              }}
            />
          </Box>

          {/* Date */}
          <Box>
            <Box
              fontWeight={FontWeight.Bold}
              fontSize={FontSize.F16}
              fontFamily={FontFamily.Museo}
              color={Colors.DarkSteelGrey}
              marginBottom={Spacing.S4}
            >
              Date
            </Box>
            <DateRangeFilter
              filter={filter}
              handleFilterApplied={handleFilter}
              setCloseOthers={() => {}}
              stylesProps={{
                borderRadius: "8px",
                width: "100%",
              }}
            />
          </Box>
        </Box>
      </Box>
    </ContentModal>
  );
};
