import { useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { getValue } from "../../utils/object";
import { useUserStore } from "../../stores/user";
import { STRIPE_PUBLISHABLE_KEY, STRIPE_PUBLISHABLE_KEY_FOR_CONNECT } from "../../constants/common";

interface options {
  stripeCountry?: string | null;
  forConnect?: boolean;
}

const useStripePromise = ({ stripeCountry, forConnect = false }: options) => {
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const { user } = useUserStore();

  useEffect(() => {
    if (!user && !stripeCountry) return;

    const country = getValue(user, "country") || stripeCountry;
    const stripeSecret = forConnect
      ? STRIPE_PUBLISHABLE_KEY_FOR_CONNECT(country)
      : STRIPE_PUBLISHABLE_KEY(country);

    loadStripe(stripeSecret).then((stripe) => setStripePromise(stripe));
  }, [user, stripeCountry]);

  return stripePromise;
};

export { useStripePromise };
