const TRICKY_NUMBER_PATTERN_AU = ["zerofour", "04", "zero4", "0four"];
const TRICKY_NUMBER_PATTERN_US = ["nineseven", "9seven", "nine7", "97"];
const noOfPreviousMessagesForPhoneNumber = 8;
// constant to pick previous message within 30 minutes
const previousMessageDiffInMinutes = 30;

const blockedCashKeywordsForChat = ["cash", "venmo", "zelle"];

const privateBookingPhrases = [
  "book privately",
  "booking privately",
  "private book",
  "private booking",
  "privately book",
];

const blockedKeywordsForChat = [
  ...blockedCashKeywordsForChat,
  ...privateBookingPhrases,
  "snapchat",
  "insta",
  "your number",
  "your phone number",
  "whatsapp",
  "viber",
  "linkedin",
  "skype",
  "my website",
  "@",
  "i n s t a g r a m",
  "o4o9",
];

export {
  TRICKY_NUMBER_PATTERN_AU,
  TRICKY_NUMBER_PATTERN_US,
  noOfPreviousMessagesForPhoneNumber,
  previousMessageDiffInMinutes,
  blockedKeywordsForChat,
  blockedCashKeywordsForChat,
  privateBookingPhrases,
};
