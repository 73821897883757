import React from "react";
import { Box } from "@material-ui/core";
import Dialog from "../Dialog";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../Button";

interface ConfirmCheckoutInterface {
  open: boolean;
  onClose: () => unknown;
  makeBooking: (e: MouseEvent) => unknown;
}

const ConfirmCheckoutModal = ({ open, onClose, makeBooking }: ConfirmCheckoutInterface) => {
  const isMobile = useMobile();

  const handleConfirm = (e: MouseEvent) => {
    onClose();
    makeBooking(e);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile}>
      <Box padding={"42px 48px"} bgcolor="#ffffff" borderRadius="11px">
        <Box
          width={isMobile ? "100%" : "352px"}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box fontFamily="Museo" fontWeight={700} fontSize="22px" color={Colors.NightBlue}>
            You already have a booking.
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize="16px"
            color={Colors.BlueyGrey}
            mt="16px"
            textAlign={"center"}
          >
            <Box>Please confirm you want to make another booking request.</Box>
          </Box>
          <Box style={styles.actionWrapper} flexDirection="row">
            <Button
              height={48}
              type={ButtonType.outlined}
              size={ButtonSize.small}
              title="Cancel"
              onClick={onClose}
              style={{ marginRight: "12px" }}
            />
            <Button
              height={48}
              type={ButtonType.secondary}
              size={ButtonSize.small}
              title="Confirm"
              onClick={(e: MouseEvent) => handleConfirm(e)}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmCheckoutModal;

const styles = {
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "32px",
  },
};
