import axios from "axios";
import create from "zustand";
import { Stripe, StripeElements } from "@stripe/stripe-js";

type State = {
  clientSecret: string | null;
  stripeCountry: string | null;
  stripe: Stripe | null;
  setStripe: (t: Stripe | null) => unknown;
  elements: StripeElements | null;
  setElements: (t: StripeElements | null) => unknown;
  createSetupIntent: (t?: string | null) => unknown;
  resetClientSecret: () => unknown;
  resetStripe: () => unknown;
};

export const useStripeStore = create<State>((set) => ({
  clientSecret: null,
  stripeCountry: null,
  stripe: null,
  setStripe: (stripe: Stripe | null) => {
    set({ stripe });
  },
  elements: null,
  setElements: (elements: StripeElements | null) => {
    set({ elements });
  },
  createSetupIntent: (token) => {
    const accessToken = localStorage.getItem("token") || token;

    if (accessToken) {
      axios
        .post("/api/v2/stripe/setup-intent", {
          accessToken,
        })
        .then((response) => {
          set({
            clientSecret: response.data.clientSecret,
            stripeCountry: response.data.stripeCountry,
          });
        })
        .catch((error) => {
          console.debug("fetch client secret error: ", error);
        });
    }
  },
  resetClientSecret: () => {
    set({ clientSecret: null });
  },
  resetStripe: () => {
    set({ stripe: null, elements: null, clientSecret: null });
  },
}));
