import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Box, CircularProgress } from "@material-ui/core";

import { User } from "../../models";
import { Paths } from "../../constants/paths";
import { Colors } from "../../constants/colors";
import { useUserStore } from "../../stores/user";
import { getCurrentTimezone } from "../../utils/date.util";
import { resetUserData } from "../../services/auth/auth.service";
import pixelsService from "../../services/pixels/pixels.service";
import { useGetLastBooking } from "../../hooks/booking/lastBooking.hooks";

export default function ReviewAndBookUser() {
  const history = useHistory();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [fetchLastBooking, setFetLastBooking] = useState<boolean>(false);

  const { isLoading: isLastBookingLoading } = useGetLastBooking({
    updateLastBookingState: true,
    shouldFetchlastBooking: fetchLastBooking,
    onSuccess: () => {
      setFetLastBooking(false);
      const url = `${Paths.ReviewAndBook}?${query.toString()}`;
      history.replace(url);
    },
  });

  const { setUser } = useUserStore();

  useEffect(() => {
    const userToken = query.get("token");
    pixelsService.trackPageView();

    let reviewURL = Paths.ReviewAndBook;
    if (userToken) {
      const localTimezone = getCurrentTimezone();
      resetUserData();
      axios
        .get("/users/me", {
          params: {
            accessToken: userToken,
            timezone: localTimezone,
          },
        })
        .then((response) => {
          if (response && response.data) {
            const data = response.data as User;

            localStorage.setItem("token", userToken);
            query.delete("token");
            setUser(data);

            setFetLastBooking(true);
            // remove token and forward remaining query params
          }
        })
        .catch((err) => {
          setFetLastBooking(false);
          history.replace(reviewURL);
        });
    } else {
      history.replace(reviewURL);
    }
  }, []);

  return (
    <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress style={{ color: Colors.NightBlue }} />
    </Box>
  );
}
