import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@material-ui/core";

import { getValue } from "../../../utils/object";
import { Colors } from "../../../constants/colors";
import { useUserStore } from "../../../stores/user";
import { DEFAULT_COUNTRY } from "../../../utils/country";
import TimeIcon from "../../../images/review-calendar-time.svg";
import LocationIcon from "../../../images/map-pin-input-icon-dark.svg";
import { AbandonedJob, Job, TherapistBooking } from "../../../models";
import { useServiceRates } from "../../../hooks/services/rates.hooks";
import { BOOKING_STATUS, DEFAULT_CURRENCY } from "../../../constants/booking";
import {
  bookingIsCancelled,
  bookingIsComplete,
  getFormattedJobDateTime,
  getMassageDescriptionForBooking,
  parseServiceDescriptionForJob,
} from "../../../helpers/booking";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LetterSpacing,
  LineHeight,
  Spacing,
} from "../../../components/v2/Styled/enum";
import { useMobile, useTab } from "../../../hooks/mobile";

interface Props {
  job?: Job;
  booking?: TherapistBooking;
  abandonedJob?: AbandonedJob;
  showAbandonBookingBtn?: boolean;
  openAbandonBookingModal?: (booking: any) => unknown;
  openBlockClientModal?: (jobId: number) => unknown;
  refetchUpdatedData?: any;
  fromProDash?: boolean;
  onClick: Function;
  selectedJobId: number;
}

const AvailableBookingCard = (props: Props) => {
  const { abandonedJob, booking, job, onClick, selectedJobId } = props;
  const { user } = useUserStore();
  const { services } = useServiceRates(user?.country || DEFAULT_COUNTRY);

  const currencySymbol = getValue(
    job?.bookingdetail?.booking,
    "service.selectedCountry.currencySymbol",
    DEFAULT_CURRENCY.currencySymbol
  );
  const payoutAmount = getValue(job, "approxServiceFee.amount", "NNN.NN");
  const isMobile = useMobile();
  const isTab = useTab();
  const getServiceLabel = () => {
    let label;

    if (booking) {
      label = getMassageDescriptionForBooking(booking?.bookingdetail.booking);
    } else if (abandonedJob) {
      label = getMassageDescriptionForBooking(abandonedJob.job.bookingdetail.booking);
    }

    if (job) {
      const isMassage = getValue(job, "bookingdetail.booking.isMassage");
      label = parseServiceDescriptionForJob({
        job,
        services,
        includeDuration: false,
        includeSessionType: false,
      });
    }

    return label;
  };

  const formatPreferredDateTime = () => {
    const booking = getValue(job, "bookingdetail.booking");
    if (!booking) return "";

    const { earliestTime, latestTime, timeOfArrival, timezone } = booking;
    return getFormattedJobDateTime({
      earliestTime,
      latestTime,
      timeOfArrival,
      timezone,
      showTextWithRange: true,
    });
  };

  const _address = () => {
    const innerBooking = job?.bookingdetail?.booking || booking?.bookingdetail?.booking;
    const innerAddress = innerBooking?.address;

    if (innerBooking && innerAddress) {
      if (innerBooking?.status === BOOKING_STATUS.NEW) {
        const { suburb, state } = innerAddress;
        return `${suburb}, ${state}`;
      }
      if (bookingIsCancelled(innerBooking) || bookingIsComplete(innerBooking) || abandonedJob) {
        return innerAddress.suburb;
      }
      return `${innerAddress.state ?? ""}, ${innerAddress.suburb} ,${innerAddress.postcode}`;
    }

    return null;
  };

  const address = _address();
  const boxProps = !(isMobile || isTab)
    ? { borderBottom: 1, borderTop: 0 }
    : { border: 1, borderRadius: Spacing.S2 };
  return (
    <Box
      id={job?.id}
      {...boxProps}
      borderColor={Colors.Mischka}
      onClick={() => onClick(job?.id)}
      style={{ cursor: "pointer" }}
      bgcolor={selectedJobId === job?.id ? Colors.PaleGrey : Colors.White}
    >
      <Box
        style={{
          marginLeft: Spacing.S6,
          marginRight: Spacing.S6,
          paddingTop: Spacing.S5,
          display: "flex",
          gap: Spacing.S4,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box style={{ display: "flex" }}>
          <Typography
            style={{
              fontFamily: FontFamily.Museo,
              fontWeight: FontWeight.Bold,
              fontSize: 16,
            }}
          >
            {getServiceLabel()}
          </Typography>
        </Box>

        <Box
          bgcolor={Colors.VeryLightGreen}
          paddingX={Spacing.S3}
          paddingY={Spacing.S2}
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          borderRadius={Spacing.S6}
          alignItems={AlignItems.center}
          justifyContent={JustifyContent.center}
          flexWrap={"nowrap"}
          flexGrow={0}
        >
          <Typography
            style={{
              color: Colors.NightBlue,
              cursor: "pointer",
              fontFamily: FontFamily.Museo,
              fontWeight: FontWeight.Bold,
              fontSize: FontSize.F14,
              lineHeight: LineHeight.L21,
              letterSpacing: LetterSpacing.S05,
              textDecoration: "underline",
            }}
            noWrap
          >
            Up to {currencySymbol}
            {payoutAmount}
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          marginLeft: Spacing.S6,
          marginRight: Spacing.S6,
          marginBottom: Spacing.S5,
          marginTop: Spacing.S2,
          display: "flex",
        }}
      >
        <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <Box style={{ display: "flex", alignItems: "flex-start", gap: 8 }}>
            <img src={TimeIcon} alt={"Time icon"} style={{ height: 21, width: 12 }} />
            <Box gap={8} style={{ marginRight: 27 }}>
              <Typography
                style={{
                  color: Colors.BlueyGrey,
                  fontFamily: FontFamily.OpenSans,
                  fontSize: FontSize.F14,
                  fontWeight: FontWeight.Medium,
                }}
              >
                Preferred date and time
              </Typography>
              <Typography
                style={{
                  color: Colors.Dusk,
                  fontFamily: FontFamily.Museo,
                  fontSize: FontSize.F14,
                  fontWeight: FontWeight.Medium,
                }}
              >
                {formatPreferredDateTime()}
              </Typography>
            </Box>
          </Box>
          <Box style={{ display: "flex", gap: 8 }}>
            <img
              src={LocationIcon}
              alt={"location icon"}
              style={{ height: 12, width: 12, marginTop: 2 }}
            />
            <Typography
              style={{
                color: Colors.Dusk,
                fontFamily: FontFamily.Museo,
                fontSize: FontSize.F14,
                fontWeight: FontWeight.Medium,
              }}
            >
              {address}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AvailableBookingCard;
