import { Box } from "@material-ui/core";
import React from "react";
import CardWrapper from "../../../components/BookingDetails/BookingDetailCard";
import Button, { ButtonType } from "../../../components/Button";
import { Colors } from "../../../constants/colors";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from "../../../components/v2/Styled/enum";
import { Text } from "../../../components/v2/Styled";
interface Props {
  onMyWay: () => unknown;
  onAbandon: () => unknown;
  onBookingUpdate: (updated: any) => unknown;
  onBookingComplete: () => unknown;
  allowCompleteButton: boolean;
  allowBookingUpdate: boolean;
  disabled: boolean;
  isPaidBooking: boolean;
  CanChatBtn: React.ReactElement;
}

const ConfirmedJobActions = ({
  onMyWay,
  onAbandon,
  onBookingUpdate,
  onBookingComplete,
  allowBookingUpdate,
  allowCompleteButton,
  disabled,
  isPaidBooking,
  CanChatBtn,
}: Props) => {
  return (
    <CardWrapper>
      <Box style={{ display: Display.Flex, flexDirection: FlexDirection.Column, gap: Spacing.S4 }}>
        <Box
          style={{ display: Display.Flex, flexDirection: FlexDirection.Column, gap: Spacing.S4 }}
        >
          {CanChatBtn}

          <Button
            title="On my way"
            type={allowCompleteButton ? ButtonType.lightSteel : ButtonType.secondary}
            onClick={onMyWay}
            disabled={allowCompleteButton || !isPaidBooking}
          />

          {allowCompleteButton ? (
            <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S2}>
              <Button
                title="Complete"
                type={ButtonType.secondary}
                onClick={onBookingComplete}
                disabled={disabled}
              />
              <Text
                font={FontFamily.OpenSans}
                size={FontSize.F14}
                weight={FontWeight.Regular}
                lineHeight={LineHeight.L21}
                color={Colors.Grey}
              >
                {" "}
                The booking time has ended, you can now complete it.
              </Text>
            </Box>
          ) : (
            <></>
          )}

          {allowBookingUpdate ? (
            <Box>
              <Button
                title="Update booking"
                type={ButtonType.outlined}
                onClick={onBookingUpdate}
                disabled={disabled}
              />
            </Box>
          ) : (
            <></>
          )}

          <Button
            title="Cancel"
            type={ButtonType.danger}
            onClick={onAbandon}
            disabled={disabled}
            colorTitle={Colors.White}
          />
        </Box>

        {!isPaidBooking && (
          <Box style={{ color: Colors.TextDanger, marginTop: 8, marginBottom: 8, fontSize: 14 }}>
            The booking is pending client payment to settle, it’s not confirmed yet.
          </Box>
        )}
      </Box>
    </CardWrapper>
  );
};

export default ConfirmedJobActions;
