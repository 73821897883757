import { Box } from "@material-ui/core";

import Dropdown from "../Dropdown";
import { Colors } from "../../constants/colors";
import { findItem } from "../../utils/object";
import { useGetBillingCompanies } from "../../hooks/billingCompany/billingCompany.hooks";

const BillingCompanyName = ({ isEditing, allowEdit, content, setContent }: any) => {
  const { data: billingCompanies } = useGetBillingCompanies();

  const billingCompaniesOptions = (billingCompanies || []).map((company: any) => ({
    title: company.name,
    value: company.id,
  }));

  return (
    <>
      {isEditing && allowEdit ? (
        <Box mr={2}>
          <Dropdown
            options={billingCompaniesOptions}
            selectedOption={findItem(billingCompaniesOptions, {
              key: "value",
              value: parseInt(content as string),
            })}
            onSelectedOption={({ value }) => setContent(value)}
            isDropdownDirectionUp={true}
            paddingTop="0px"
            paddingBottom="0px"
          />
        </Box>
      ) : (
        <Box style={styles.text} mt={1}>
          {findItem(billingCompaniesOptions, {
            key: "value",
            value: parseInt(content as string),
          })?.title || ""}
        </Box>
      )}
    </>
  );
};

const styles = {
  text: {
    color: Colors.Dusk,
    fontSize: "16px",
    fontFamily: "museo",
    opacity: 1,
    WebkitTextFillColor: Colors.Dusk,
  },
};

export default BillingCompanyName;
