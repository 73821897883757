import pixelEvents from "./facebook/pixelsEvents.service";

const trackPageView = () => {
  try {
    const source = localStorage.getItem("bookingSource");
    pixelEvents.trackPageView(source);
  } catch (error) {
    console.error("Error tracking page view", error);
  }
};

const trackAddToCart = (data: any) => {
  try {
    const source = localStorage.getItem("bookingSource");
    pixelEvents.trackAddToCart(data, source);
  } catch (error) {
    console.error("Error tracking add to cart", error);
  }
};

const trackInitiateCheckout = (data: any) => {
  try {
    const source = localStorage.getItem("bookingSource");
    pixelEvents.trackInitiateCheckout(data, source);
  } catch (error) {
    console.error("Error tracking initiate checkout", error);
  }
};

const trackPurchase = (data: any) => {
  try {
    const source = localStorage.getItem("bookingSource");
    pixelEvents.trackPurchase(data, source);
  } catch (error) {
    console.error("Error tracking purchase", error);
  }
};

const pixelsService = {
  trackPageView,
  trackAddToCart,
  trackInitiateCheckout,
  trackPurchase,
};

export default pixelsService;
