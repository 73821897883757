import { Box, InputBase, Typography, makeStyles, withStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import { DEBOUNCE_WAIT_TIMEOUT, VERIFICATION_CODE_RESEND_TIMEOUT } from "../../constants/time";
import { REGEX } from "../../data/enums";
import { parseApiV2Error } from "../../helpers/error";
import { useMobile } from "../../hooks/mobile";
import { useVerifyEmail, useVerifyEmailRequest } from "../../hooks/security.hooks";
import { resetUserData } from "../../services/auth/auth.service";
import { useAlertStore } from "../../stores/alert";
import { getFutureTimeStamp } from "../../utils/date.util";
import Button, { ButtonSize, ButtonType } from "../Button";
import Dialog from "../Dialog";
import DuplicateEmailError from "../Error/DuplicateEmailError";
import CircleExclamation from "../Icons/CircleExclamation";
import CountdownTimer from "../MobileModalOtp/CountdownTimer";
import MobileInputAction from "../MobileModalOtp/MobileInputAction";
import VerifiedBadge from "./VerifiedBadge";
import { useUserStore } from "../../stores/user";
import { getValue } from "../../utils/object";
import { Cursor, FontFamily, FontSize, FontWeight } from "../v2/Styled/enum";

const ErrorWrapper: React.FC = ({ children }) => {
  return (
    <span
      style={{
        color: "#f24a58",
        fontSize: "12px",
        textAlign: "left",
        marginTop: "5px",
      }}
    >
      {children}
    </span>
  );
};

interface Props {
  email?: string;
  onVerified: (email: string) => void;
  isVerified?: boolean;
  inputPlaceholder?: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
  verifiedMessage?: string;
  forProSignUp?: boolean;
  forSignUp?: boolean;
  paddingTop?: string;
  paddingBottom?: string;
  onLoginWithThisEmailClick?: (email: string) => void;
}

const StyledInputBase = withStyles({
  input: {
    cursor: "pointer",
    "&::placeholder": {
      color: Colors.LightBlueGrey,
      opacity: 1.0,
    },
  },
})(InputBase);

const input = {
  borderRadius: "2px",
  padding: "12px 16px",
  borderColor: Colors.LightPeriwinkle,
  borderWidth: 1,
  borderStyle: "solid",
  fontSize: 18,
  backgroundColor: Colors.White,
  "&::placeholder": {
    color: Colors.LightBlueGrey,
    opacity: 1.0,
  },
};

const BoxedInput = withStyles({
  input,
})(InputBase);

const useStyles = makeStyles({
  otpInput: {
    ...input,
    "&:focus-visible": {
      outline: "none",
    },
  },
});

const EmailVerificationModal = (props: Props): JSX.Element => {
  const isMobile = useMobile();
  const { setSuccessMessage } = useAlertStore();
  const history = useHistory();
  const { user } = useUserStore();
  const classes = useStyles();

  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [verifiedEmail, setVerifiedEmail] = React.useState("");
  const [otpModal, setOtpModal] = React.useState(false);

  const shouldIncudeToken = () => {
    const forSignup = getValue(props, "forSignUp", false);
    return !forSignup;
  };

  const { isLoading: isSending, requestEmailVerification } =
    useVerifyEmailRequest(shouldIncudeToken());
  const { isLoading: isVerifying, verifyEmail } = useVerifyEmail(shouldIncudeToken());

  // MODAL STATE
  const [email, setEmail] = React.useState<string>("");

  const [hasSendOtp, setHasSendOtp] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState("");
  const [otpError, setOtpError] = React.useState<string | React.ReactNode>("");
  const [shouldShowTimer, setShouldShowTimer] = React.useState(false);
  const [isDebouncedError, setIsDebouncedError] = React.useState(false);

  const [waitTime, setWaitTime] = React.useState("");
  const [isActionDisabled, setIsActionDisabled] = React.useState(true);

  React.useEffect(() => {
    const isEmailVerified = !!getValue(user, "emailVerifiedAt");
    const email = isEmailVerified ? "" : getValue(user, "email", "");
    setEmail(email);
  }, [user]);

  React.useEffect(() => {
    setOtpModal(props.open || false);
  }, [props.open]);

  React.useEffect(() => {
    if (props.email) {
      setVerifiedEmail(props.email);
    }
  }, [props.email]);

  React.useEffect(() => {
    setIsVerified(!!props.isVerified);
  }, [props.isVerified]);

  React.useEffect(() => {
    if (otpError == "Please try again later") {
      setIsDebouncedError(true);
      setShouldShowTimer(true);
    } else {
      setShouldShowTimer(false);
      setIsDebouncedError(false);
    }
  }, [otpError]);

  React.useEffect(() => {
    if (!otpModal) {
      setOtpCode("");
      setHasSendOtp(false);
      setOtpError("");
    }
  }, [otpModal]);

  // ============================= FUNCTION ===================================

  const getVerificationCode = async () => {
    setOtpError("");

    if (!REGEX.EMAIL.test((email || "").toLowerCase())) {
      setOtpError("Please enter correct email address.");
      return;
    }
    const data = { email, forProSignup: !!props.forProSignUp };

    requestEmailVerification(data, {
      onError: (error: any) => {
        if (error.response?.data?.error?.data?.isDuplicateEmail) {
          setOtpError(<DuplicateEmailError onClick={handleAccountChange} />);
        } else {
          setOtpError(parseApiV2Error(error));
        }
      },
      onSuccess: () => {
        setHasSendOtp(true);
        setWaitTime(getFutureTimeStamp({ seconds: VERIFICATION_CODE_RESEND_TIMEOUT }).format());
        setIsActionDisabled(true);
      },
    });
  };

  const verifyOtp = async () => {
    verifyEmail(
      { email, code: otpCode },
      {
        onSuccess: (data: any) => {
          setVerifiedEmail(email);
          setOtpModal(false);
          props.onVerified(email);
          setIsVerified(true);
          setSuccessMessage(props.verifiedMessage || data.message);
        },
        onError: () => setOtpError("Invalid verification code"),
      }
    );
  };

  const handleOpenModal = () => {
    setOtpModal(true);
  };

  const handleChangeEmail = () => {
    setOtpCode("");
    setHasSendOtp(false);
    setOtpError("");
  };

  const handleCloseModal = () => {
    setOtpModal(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleAccountChange = () => {
    resetUserData();

    if (props.onLoginWithThisEmailClick) {
      handleCloseModal();
      props.onLoginWithThisEmailClick(email);
      setEmail("");
    } else {
      history.push(Paths.Login);
    }
  };

  return (
    <>
      {props.inputPlaceholder ? (
        <Box onClick={handleOpenModal}>{props.inputPlaceholder}</Box>
      ) : (
        <Box width={"100%"} paddingTop={props.paddingTop} paddingBottom={props.paddingBottom}>
          <Box fontFamily="Museo" fontSize="14px" color={Colors.Dusk} fontWeight={700}>
            Email
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" mt={1} mb={1}>
            <StyledInputBase
              disabled={false}
              value={verifiedEmail}
              style={{
                color: Colors.TurquoiseBlue,
              }}
              fullWidth
              onFocus={(e) => {
                handleOpenModal();
                e.target.blur();
              }}
              placeholder={"Email"}
            />
            {isVerified && <VerifiedBadge />}
            {!isVerified && props.email && (
              <Box
                color={Colors.Danger}
                fontWeight={FontWeight.Bold}
                fontSize={FontSize.F14}
                fontFamily={FontFamily.OpenSans}
                style={{ cursor: Cursor.pointer }}
                onClick={() => handleOpenModal()}
              >
                Verify
              </Box>
            )}
          </Box>
          <Box bgcolor={Colors.LightPeriwinkle} height="2px" width="100%" />
          {!isVerified && props.email && (
            <Box
              display="flex"
              gridGap={8}
              alignItems={"center"}
              fontFamily={"Open sans"}
              mt={1}
              fontSize={14}
            >
              <CircleExclamation />
              <Box color={Colors.Danger}>Please verify your email address</Box>
            </Box>
          )}
        </Box>
      )}

      <Dialog
        open={otpModal}
        onClose={handleCloseModal}
        maxWidth={"xl"}
        fullScreen={isMobile}
        style={{ height: "450px" }}
      >
        <Box maxHeight={"365px"} style={{ padding: "48px 48px 24px 48px" }}>
          {!hasSendOtp ? (
            <>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  style={{
                    fontSize: "24px",
                    marginBottom: "0px",
                    width: "100%",
                    color: "#301E79",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Enter an email address
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "100%",
                    color: "#41506F",
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  You will receive an email with verification code.
                </Typography>
              </Box>

              <Box paddingX={isMobile ? "22px" : "88px"} marginTop={"32px"}>
                <Typography
                  style={{
                    height: "32px",
                    lineHeight: "110%",
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "100%",
                    color: "#41506F",
                    fontWeight: 600,
                  }}
                >
                  Email
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: "100%" }}
                  className="verifyPhonePicker"
                >
                  <BoxedInput
                    placeholder="youremail@mail.com"
                    type="email"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                  />
                  {otpError && !isDebouncedError && <ErrorWrapper>{otpError}</ErrorWrapper>}
                  {otpError && isDebouncedError && shouldShowTimer && (
                    <ErrorWrapper>
                      {otpError}
                      <CountdownTimer
                        time={getFutureTimeStamp({ seconds: DEBOUNCE_WAIT_TIMEOUT }).format()}
                        setIsActionDisabled={setShouldShowTimer}
                        style={{ color: "#f24a58" }}
                      />
                    </ErrorWrapper>
                  )}
                </Box>
              </Box>
              <Box style={{ marginTop: "60px" }}>
                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    style={{ marginTop: "20px" }}
                    type={ButtonType.outlined}
                    size={ButtonSize.large}
                    title="Cancel"
                    width="244px"
                    onClick={handleCloseModal}
                    disabled={isSending}
                  />
                  <Box width="14.5px" />
                  <Button
                    style={{ marginTop: "20px" }}
                    type={ButtonType.indigo}
                    size={ButtonSize.large}
                    title="Next"
                    width="244px"
                    onClick={getVerificationCode}
                    loading={isSending}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  style={{
                    fontSize: "24px",
                    marginBottom: "0px",
                    width: "100%",
                    color: "#301E79",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Verify your email address
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "100%",
                    color: "#41506F",
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  Enter the 6 digit code we sent to {email}
                </Typography>
              </Box>
              <Box paddingX={isMobile ? "22px" : "88px"} marginTop={"32px"}>
                <Box display="flex" flexDirection="column">
                  <input
                    placeholder="123456"
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                    maxLength={6}
                    className={classes.otpInput}
                  />
                  {otpError && <ErrorWrapper>{otpError}</ErrorWrapper>}
                </Box>
              </Box>
              <Box marginTop={"58px"}>
                <MobileInputAction
                  onResend={getVerificationCode}
                  onChange={handleChangeEmail}
                  retryIn={waitTime}
                  isActionDisabled={isActionDisabled}
                  setIsActionDisabled={setIsActionDisabled}
                  changeText="Change email address"
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  marginTop={"12px"}
                >
                  <Button
                    style={{ marginTop: "20px" }}
                    type={ButtonType.outlined}
                    size={ButtonSize.large}
                    title="Cancel"
                    width="244px"
                    onClick={handleCloseModal}
                    disabled={isVerifying || isSending}
                  />
                  <Box width="14.5px" />
                  <Button
                    style={{ marginTop: "20px" }}
                    size={ButtonSize.large}
                    title="Verify"
                    width="244px"
                    onClick={verifyOtp}
                    loading={isVerifying || isSending}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
};

EmailVerificationModal.defaultProps = {
  paddingTop: "21px",
  paddingBottom: "21px",
};

export default EmailVerificationModal;
