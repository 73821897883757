import { isEmpty } from "lodash";
import { TreatmentFor } from "../../constants/booking";
import { BOOKING_SESSION_TYPE } from "../../helpers/booking";
import { Booking } from "../../models";
import { getBookingQuestions, getMassageService, useBookingStore } from "../../stores/booking";
import { checkIfEmpty, getValue } from "../../utils/object";

import { QUERY_KEYS } from "../../constants/queryKey";
import { useQuery } from "@tanstack/react-query";
import { get } from "../../api/client";
import { setCountryCode } from "../../utils/country";

export const useGetLastBooking = ({
  updateLastBookingState = false,
  shouldFetchlastBooking = true,
  onSuccess,
}: {
  updateLastBookingState?: boolean;
  shouldFetchlastBooking?: boolean;
  onSuccess?: () => unknown;
}) => {
  const {
    setAddress,
    setLastBooking,
    setTreatmentDetails,
    setNumOfRecipients,
    resetTreatmentLength,
    resetTreatmentDetails,
    setServiceId,
    setTreatmentId,
    setUpdatingForBooking,
  } = useBookingStore();

  return useQuery([QUERY_KEYS.LAST_BOOKING], () => get("bookings/last?channel=web"), {
    onSuccess: async (data) => {
      const lastBooking = data;

      if (updateLastBookingState) {
        if (!lastBooking) {
          setUpdatingForBooking(null);
          setLastBooking(null);
          return;
        }

        const lastBookingAddress = getValue(lastBooking, "address");
        const { duration: serviceDurations = [] } = getMassageService();
        const DEFAULT_SERVICE_DURATION = serviceDurations[0]?.value || 60;

        setAddress(lastBookingAddress);
        await Promise.all([getBookingQuestions(), setLastBooking(lastBooking)]);
        setCountryCode(lastBooking?.address?.countryCode || "AU");

        if (
          lastBooking.bookingdetails &&
          lastBooking.isMassage &&
          lastBooking.bookingdetails.length &&
          lastBooking.bookingdetails[0].treatmentDetails
        ) {
          const serviceDurationFromLastBooking = lastBooking.bookingdetails[0].serviceDuration;
          const equivalentDuration = serviceDurations.find(
            (duration) => duration.value === serviceDurationFromLastBooking
          );

          if (!equivalentDuration) {
            lastBooking.bookingdetails[0].treatmentDetails[0].serviceDuration =
              DEFAULT_SERVICE_DURATION;
            lastBooking.bookingdetails[0].serviceDuration = DEFAULT_SERVICE_DURATION;
          }

          if (lastBooking.bookingdetails[1]?.treatmentDetails) {
            const serviceDurationFromLastBooking2 = lastBooking.bookingdetails[1].serviceDuration;
            const equivalentDuration2 = serviceDurations.find(
              (duration) => duration.value === serviceDurationFromLastBooking2
            );

            if (!equivalentDuration2) {
              lastBooking.bookingdetails[1].treatmentDetails[0].serviceDuration =
                DEFAULT_SERVICE_DURATION;
              lastBooking.bookingdetails[1].serviceDuration = DEFAULT_SERVICE_DURATION;
            }
          }

          setLastBooking(lastBooking);
        }

        if (
          lastBooking.bookingdetails &&
          lastBooking.isHairAndMakeup &&
          lastBooking.bookingdetails.length &&
          lastBooking.bookingdetails[0].treatmentDetails
        ) {
          const treatmentDetails = lastBooking.bookingdetails[0].treatmentDetails.map(
            (detail: any) => ({
              treatmentFor: detail.treatmentFor || TreatmentFor.myself,
              treatmentId: detail.treatmentTypeId,
              noteForProvider: detail.noteForProvider,
            })
          );
          setTreatmentDetails(treatmentDetails);
          setNumOfRecipients(lastBooking.numberOfClients || 1);
        }

        const isCorporateOrGroup =
          lastBooking.sessionType === BOOKING_SESSION_TYPE.CORPORATE ||
          lastBooking.sessionType === BOOKING_SESSION_TYPE.GROUP_BOOKING;
        if (isCorporateOrGroup && lastBooking.isManual) {
          lastBooking.bookingdetails = [];
          lastBooking.isActuallyPaid = false;
          lastBooking.isPaid = false;
          setLastBooking(lastBooking);
          if (isEmpty(lastBooking.corporateInfo)) {
            setServiceId(null);
            setTreatmentId(null);
            resetTreatmentLength();
            resetTreatmentDetails();
          }
        }
        setLastBooking(lastBooking);
      }

      onSuccess && onSuccess();
    },
    enabled: shouldFetchlastBooking,
  });
};
